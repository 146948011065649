import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { Store } from '@ngrx/store';
import { clearETicket, updateUpcomingMyTicket } from 'src/app/actions/mytickets.actions';
import { updateCurrentMySeasonPass } from 'src/app/actions/myseasonpasses.actions';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async transferTicket(fixtureSlug: string, ticketUuid: string, email: string) {
    try {
      const res = await this._apiRequestService.postRequest('transfer/ticket', {
        email_address: email,
        fixture_slug : fixtureSlug,
        ticket_uuid: ticketUuid,
      })
      return true
    } catch(e: any) {
      return e.error
    }   
  }

  async transferSeasonPass(seasonPassSlug: string, seasonPassPurchaseUuid: string, email: string) {
    try {
      const res = await this._apiRequestService.postRequest('transfer/season-pass', {
        email_address: email,
        season_pass_slug : seasonPassSlug,
        season_pass_purchase_uuid: seasonPassPurchaseUuid,
      })
      return true
    } catch(e: any) {
      return e.error
    }   
  }
  

  async revokeTicket(fixtureSlug: string, ticketUuid: String) {
    try {
      const res = await this._apiRequestService.deleteRequest('transfer/revoke-ticket/' + fixtureSlug + '/' + ticketUuid)
      this._store.dispatch(updateUpcomingMyTicket({ticket: res.data, fixtureSlug: fixtureSlug}))
      return true
    } catch(e: any) {
      return e.error
    }   
  }

  async revokeSeasonPass(seasonPassSlug: string, seasonPassPurchaseUuid: String) {
    try {
      const res = await this._apiRequestService.deleteRequest('transfer/revoke-season-pass/' + seasonPassSlug + '/' + seasonPassPurchaseUuid)
      this._store.dispatch(updateCurrentMySeasonPass({seasonPassPurchase: res.data, seasonPassSlug: seasonPassSlug}))
      return true
    } catch(e: any) {
      return e.error
    }   
  }
  
}
