<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>x</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g id="szamlazasiadatok" transform="translate(-1247.000000, -163.000000)" [attr.stroke]="stroke">
            <g id="Group-10" transform="translate(896.000000, 140.000000)">
                <g id="x" transform="translate(352.000000, 24.000000)">
                    <line x1="0.5" y1="0.5" x2="11.5" y2="11.5" id="Line-2"></line>
                    <line x1="0.5" y1="0.5" x2="11.5" y2="11.5" id="Line-2" transform="translate(6.000000, 6.000000) scale(-1, 1) translate(-6.000000, -6.000000) "></line>
                </g>
            </g>
        </g>
    </g>
</svg>