<div
class="scroll-smooth fixed right-0 top-[0px] pt-10 pb-[50px] max-w-[min(100vw,500px)] w-full bg-white h-screen z-[999] overflow-y-scroll"
>
<button
  (click)="handleClose"
  class="absolute top-10 right-[47px] transition-all hover:rotate-[360deg]"
>
<app-x></app-x>
</button>
<h1 class="text-[15px] font-bold px-12">
  <span class="text-[var(--red)]">Game</span>Quiz
</h1>
<div *ngClass="{'h-full' : isRequesting}">

  <div *ngIf="isRequesting" class="w-full flex items-center justify-center h-full">
    
  </div>
  <form *ngClass="{'mt-[40px]': true, 'hidden' : isRequesting}">
    <app-tabs-holder
      (answerSelected)="handleAnswerSelected"
      (ended)="submitForm()"
      [items]="game.questions"
    >
      
    </app-tabs-holder>
  </form>

</div>
</div>
