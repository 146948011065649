import { createReducer, on } from "@ngrx/store";
import { clearCart, setCart } from "../actions/cart.actions";
import { setSoldSeats } from "../actions/seats.actions";
import { Cart } from "../interfaces/State/Cart/cart";
import {  } from "../interfaces/State/Seat/sold-seats";

export const initialState = {} as Cart

export const CartReducer = createReducer(
    initialState,
    on(setCart, (state, {cart}) => ({...state, ...cart})),
    on(clearCart, () => ({} as Cart)),
);