import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GameQuizComponent } from 'src/app/components/game/game-quiz/game-quiz.component';
import { GameView } from 'src/app/interfaces/State/Game/GameView';
import { GameService } from 'src/app/services/Game/game.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit, OnDestroy {

  public slug: string = '';
  public game : GameView = {} as GameView;
  private _subscription?: Subscription;

  

  constructor(
    private _gameService: GameService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store,
  ) {}

  ngOnInit(): void {
    this._init();
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  async _init()
  {
    this.slug = this._route.snapshot.paramMap.get('id')!;

    this._gameService.getGameBySlug(this.slug);

    this._subscription = this._store.subscribe((state: any) => {
      this.game = state.game as GameView; 
    });


  }
  toHomepage()
  {
    this._router.navigateByUrl('');
  }
  toCompleted()
  {
    this._router.navigateByUrl(`games/${this.slug}/completed`);
  }
}
