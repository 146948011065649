import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-x',
  templateUrl: './x.component.html',
  styleUrls: ['./x.component.scss']
})
export class XComponent {
 @Input() stroke: string = '#000' 
}
