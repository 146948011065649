import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  error: string = ''
  isRequesting: boolean = false
  private formSubmitAttempt: boolean = false;
  @Output() showRegisterFormEvent: EventEmitter<string> = new EventEmitter();
  @Output() showForgotPasswordFormEvent: EventEmitter<string> = new EventEmitter();

  loginForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(
    private _modalService: ModalService,   
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
  ) {
  }

  hideModal() {
    this._modalService.hideModal()
  }
  
  setValue(property: string, value: any) {
    this.loginForm.get(property)?.setValue(value)
  }

  showForgotPasswordForm() {
    this.showForgotPasswordFormEvent.emit()
  }

  showRegisterForm() {
    this.showRegisterFormEvent.emit();
  }

  async onSubmit() {
    if(this.isRequesting) return ;
    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""
    if(!this.loginForm.valid) {
      this.isRequesting = false
      return ;
    }
  
    const res = await this._authService.login(this.email!.value!, this.password!.value!)

    this.isRequesting = false

    if(res === true) {
      this.reset()
      this.hideModal()
      return ;
    }

    this.error = res
  }

  reset() {
    this.loginForm.reset()
    this.formSubmitAttempt = false
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.loginForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.loginForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.loginForm.get(field)!.valid && this.formSubmitAttempt
  }

  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }

}
