import { createReducer, on } from "@ngrx/store";
import { hideModal, showModal } from "../actions/modal.actions";
import { Modals } from "../interfaces/State/Modal/modals";

export const initialState = {
    showModal: false,
    modal : {
        type: undefined,
        wrapperCssClasses: null,
        data : {},
    },
} as Modals

export const ModalReducer = createReducer(
    initialState,
    on(showModal, (state, {wrapperCssClasses, modalType, data}) => ({...state, showModal: true, modal : {...state.modal, wrapperCssClasses: wrapperCssClasses, modalType : modalType, data : data}})),
    on(hideModal, (state) => ({...state, showModal: false})),
);