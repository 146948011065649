import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  constructor() { }

  encrypt(data: any) {
    const client = environment.environment == 'development' ? environment.client : window.location.hostname.split('.')[0] 
    const encryptDecryptKey = environment.clients[client as keyof typeof environment.clients].frontendEncryptDecryptKey;

    let iv: any = CryptoJS.lib.WordArray.random(16),
    key = CryptoJS.enc.Base64.parse(encryptDecryptKey);
    
    let options = {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    };
    let encrypted: any = CryptoJS.AES.encrypt(data, key, options);
    encrypted = encrypted.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result: any = {
      iv: iv,
      value: encrypted,
      mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    return CryptoJS.enc.Base64.stringify(result);
  }

  decrypt(encryptStr: any) {
    const client = environment.environment == 'development' ? environment.client : window.location.hostname.split('.')[0] 
    const encryptDecryptKey = environment.clients[client as keyof typeof environment.clients].frontendEncryptDecryptKey;

    encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
    encryptData = JSON.parse(encryptData);
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
    var decrypted:any = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(encryptDecryptKey), {
        iv : iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
  }
}
