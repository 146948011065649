import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GameQuestion } from 'src/app/interfaces/State/Game/GameQuestion';
import { GameView } from 'src/app/interfaces/State/Game/GameView';
import { ApiRequestService } from 'src/app/services/ApiRequest/api-request.service';
import { GameService } from 'src/app/services/Game/game.service';

@Component({
  selector: 'app-game-quiz',
  templateUrl: './game-quiz.component.html',
  styleUrls: ['./game-quiz.component.scss']
})
export class GameQuizComponent implements OnInit {
  @Input() game : GameView = {} as GameView;

  @Output() finished = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  constructor(
    private _apiRequestService: ApiRequestService,
    private _gameService: GameService,
  ) {}

  ngOnInit(): void {
    this._init();
  }
  async _init ()
  {
  }

  private answers: number[] = [] as number[];

  public isRequesting: boolean = false;

  async submitForm()
  {
    this.isRequesting = true;
    try{
      let r = await this._apiRequestService.putRequest(`games/${this.game.url}/answer`, {
        answers: this.answers,
      });

      this.finished.emit();
    } catch(e: any){
      console.error("Error happened while saving answers!", e.error.error);
    } finally {
      this.isRequesting = false;
    }
    
    
  }
  async handleAnswerSelected(answerId: number)
  {
    this.answers.push(answerId);
  }
  async handleClose()
  {
    this.isRequesting = false;
    this.close.emit();
  }
}
