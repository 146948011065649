import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialAccessService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public async validateCode(code: string, fixtureSlug: string) {
    try {
      const res = await this._apiRequestService.postRequest('special-access/validate', { 'code': code, 'fixture_slug' : fixtureSlug })

      return true
    } catch (e: any) {
      return e.error.error;
    }
  }
}
