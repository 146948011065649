<div class="container p-10 bg-white rounded-[20px] !my-[30px] sm:p-5" [ngClass]="{'!my-0 !max-w-full !rounded-none !-mt-[10px] overflow-x-hidden' : mapOnly}" >
<p class="p1"><strong>&Aacute;ltal&aacute;nos Szerződ&eacute;si Felt&eacute;telek</strong></p>
<p class="p2">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p4">&nbsp;</p>
<p class="p4">&nbsp;</p>
<p class="p5"><strong>I. Szolg&aacute;ltat&oacute; adatai</strong></p>
<p class="p6">&nbsp;</p>
<p class="p7">A jelen V&aacute;s&aacute;rl&oacute;i &Aacute;ltal&aacute;nos Szerződ&eacute;si Felt&eacute;telek (a tov&aacute;bbiakban: &Aacute;SZF) hat&aacute;rozza meg a Szolg&aacute;ltat&oacute;, valamint az internetes rendszer&eacute;t haszn&aacute;l&oacute; term&eacute;szetes vagy jogi szem&eacute;ly (a tov&aacute;bbiakban: Felhaszn&aacute;l&oacute;) k&ouml;z&ouml;tt bel&eacute;pőjegyek, b&eacute;rletek &eacute;s egy&eacute;b szolg&aacute;ltat&aacute;sok megv&aacute;s&aacute;rl&aacute;s&aacute;ra vonatkoz&oacute; szolg&aacute;ltat&aacute;s ig&eacute;nybev&eacute;tel&eacute;nek felt&eacute;teleit, valamint a Szolg&aacute;ltat&oacute; &eacute;s a Felhaszn&aacute;l&oacute; k&ouml;z&ouml;tt keletkező jogokat &eacute;s k&ouml;telezetts&eacute;geket.</p>
<p class="p8">&nbsp;</p>
<p class="p9"><span class="s1"><strong>N&eacute;v: </strong></span>Csakjegyek Kft.</p>
<p class="p9"><span class="s1"><strong>Sz&eacute;khely: </strong></span>1163 Budapest, Czir&aacute;ki utca 24-32. A1. &eacute;p. 2. em. 110. ajt&oacute;</p>
<p class="p9"><span class="s1"><strong>Ad&oacute;sz&aacute;m: </strong></span>25935576-2-42</p>
<p class="p10"><strong>C&eacute;gjegyz&eacute;ksz&aacute;m: </strong><span class="s2">01 09 297248</span></p>
<p class="p11"><span class="s3"><strong>E-mail c&iacute;m: </strong><a href="mailto:info@csakjegyek.hu"><span class="s4"><strong>info@csakjegyek.hu</strong></span></a></span></p>
<p class="p12"><span class="s1"><strong>Weboldal: </strong></span>csakjegyek.hu</p>
<p class="p8">&nbsp;</p>
<p class="p5"><strong>I. Fogalom meghat&aacute;roz&aacute;sok</strong></p>
<p class="p13">&nbsp;</p>
<p class="p7">Adatkezel&eacute;si Szab&aacute;lyzat: a Csakjegyek Kft. &aacute;ltal v&eacute;gzett adatkezel&eacute;sről sz&oacute;l&oacute; t&aacute;j&eacute;koztat&oacute; dokumentum, amely a weboldal l&aacute;bl&eacute;c&eacute;ben &eacute;rhető el.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Applik&aacute;ci&oacute;k: A Csakjegyek Kft. &aacute;ltal Apple App Store-ba &eacute;s Google Play Store-ba felt&ouml;lt&ouml;tt applik&aacute;ci&oacute;k.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Bel&eacute;pőjegy: Egy szem&eacute;ly sz&aacute;m&aacute;ra, egyszeri bel&eacute;p&eacute;sre jogos&iacute;t&oacute;, vonalk&oacute;ddal ell&aacute;tott dokumentum, mely lehet pap&iacute;r alap&uacute;, let&ouml;lthető PDF form&aacute;tum&uacute;, elektronikus jegy vagy Apple Wallett p&eacute;nzt&aacute;rc&aacute;ba export&aacute;lhat&oacute; elektronikus jegy.</p>
<p class="p14">&nbsp;</p>
<p class="p7">B&eacute;rlet: Egy szem&eacute;ly sz&aacute;m&aacute;ra, esem&eacute;nyenk&eacute;nt egyszeri bel&eacute;p&eacute;sre jogos&iacute;t&oacute;, vonalk&oacute;ddal ell&aacute;tott dokumentum, mely t&ouml;bb, előre meghat&aacute;rozott esem&eacute;nyre is &eacute;rv&eacute;nyes. Egy b&eacute;rlet lehet pap&iacute;r alap&uacute;, let&ouml;lthető PDF form&aacute;tum&uacute;, elektronikus jegy vagy Apple Wallett p&eacute;nzt&aacute;rc&aacute;ba export&aacute;lhat&oacute; elektronikus jegy.</p>
<p class="p14">&nbsp;</p>
<p class="p7">B&eacute;rlet&aacute;r: A Szolg&aacute;ltat&oacute; &eacute;s Megb&iacute;z&oacute;ja &aacute;ltal meghat&aacute;rozott brutt&oacute; &aacute;r, mely minden esetben egy b&eacute;rlethez tartozik.</p>
<p class="p14">&nbsp;</p>
<p class="p7">B&eacute;rlet t&iacute;pusok: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal meghat&aacute;rozott b&eacute;rlet fajt&aacute;k, melyek jelen dokumentum alapj&aacute;n tartalmazhatnak korl&aacute;toz&aacute;sokat arra vonatkoz&oacute;an, hogy mely Felhaszn&aacute;l&oacute;k v&aacute;s&aacute;rolhatj&aacute;k meg.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Esem&eacute;ny: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal szervezett rendezv&eacute;ny.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Esem&eacute;ny adatai: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal szervezett rendezv&eacute;ny adatai (t&ouml;bbek k&ouml;z&ouml;tt: fell&eacute;pők list&aacute;ja, sportcsapatok list&aacute;ja, esem&eacute;ny helysz&iacute;ne, esem&eacute;ny időpontja)</p>
<p class="p14">&nbsp;</p>
<p class="p7">Esem&eacute;ny időpontja: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal szervezett rendezv&eacute;ny kezd&eacute;si időpontja.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute;: A <a href="http://csakjegyek.hu"><span class="s5">csakjegyek.hu</span></a> weboldalt, illetve a Csakjegyek applik&aacute;ci&oacute;t haszn&aacute;l&oacute; szem&eacute;ly.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute;i fi&oacute;k: Egy Felhaszn&aacute;l&oacute; &aacute;ltal Regisztr&aacute;ci&oacute; &uacute;tj&aacute;n, szem&eacute;lyes adatainak megad&aacute;s&aacute;val l&eacute;trehozott fi&oacute;k a Weboldalon vagy Applik&aacute;ci&oacute;kban.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Jegy&aacute;r: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal meghat&aacute;rozott brutt&oacute; &aacute;r, mely minden esetben egy jegyhez tartozik.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Jegyrendszer: Weboldal &eacute;s Applik&aacute;ci&oacute;k &aacute;ltal haszn&aacute;lt rendszer, mely a jegy &eacute;s b&eacute;rlet&eacute;rt&eacute;kes&iacute;t&eacute;si folyamat lebonyol&iacute;t&aacute;s&aacute;ra szolg&aacute;l. A Jegyrendszer sz&aacute;mos integr&aacute;ci&oacute;t tartalmaz k&uuml;lső, harmadik f&eacute;l szolg&aacute;ltat&oacute;kkal (pl. Fizet&eacute;si szolg&aacute;ltat&oacute;, sz&aacute;ml&aacute;z&aacute;si szolg&aacute;ltat&oacute;, bel&eacute;ptető kaput &uuml;zemeltető szolg&aacute;ltat&oacute;).</p>
<p class="p14">&nbsp;</p>
<p class="p7">Jegy t&iacute;pusok: A Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja &aacute;ltal meghat&aacute;rozott jegy fajt&aacute;k, melyek jelen dokumentum alapj&aacute;n tartalmazhatnak korl&aacute;toz&aacute;sokat arra vonatkoz&oacute;an, hogy mely Felhaszn&aacute;l&oacute;k v&aacute;s&aacute;rolhatj&aacute;k meg.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Kos&aacute;r: Egy virtu&aacute;lis kos&aacute;r, melybe egy Felhaszn&aacute;l&oacute; maximum 4 term&eacute;ket helyezhet &eacute;s 20 perce van v&eacute;gleges&iacute;teni a V&aacute;s&aacute;rl&aacute;st.</p>
<p class="p15">&nbsp;</p>
<p class="p7">Megb&iacute;z&oacute;: Szolg&aacute;ltat&oacute;val szerződ&eacute;ses viszonyban &aacute;ll&oacute; rendezv&eacute;nyszervezők, melyek megb&iacute;z&aacute;s&aacute;b&oacute;l Szolg&aacute;ltat&oacute; jegy &eacute;s b&eacute;rlet &eacute;rt&eacute;kes&iacute;t&eacute;st, illetve egy&eacute;b Szolg&aacute;ltat&aacute;sokat v&eacute;gez.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Szolg&aacute;ltat&oacute;: A weboldalt &eacute;s applik&aacute;ci&oacute;kat &uuml;zemeltető Csakjegyek Kft.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Szolg&aacute;ltat&aacute;si d&iacute;j: A weboldalt &eacute;s applik&aacute;ci&oacute;kat &uuml;zemeltető Csakjegyek Kft. &aacute;ltal meghat&aacute;rozott, Felhaszn&aacute;l&oacute; &aacute;ltal Bel&eacute;pőjegyenk&eacute;nt &eacute;s B&eacute;rletenk&eacute;nt k&ouml;telezően fizetendő brutt&oacute; d&iacute;j, mely a Jegyrendszer haszn&aacute;lat&aacute;nak a d&iacute;ja, &iacute;gy nem tartozik a Jegy&aacute;rhoz &eacute;s B&eacute;rlet &aacute;rhoz.</p>
<p class="p14">&nbsp;</p>
<p class="p7">V&aacute;s&aacute;rl&oacute;: Felhaszn&aacute;l&oacute;i fi&oacute;kkal rendelkező Felhaszn&aacute;l&oacute;.</p>
<p class="p14">&nbsp;</p>
<p class="p7">V&aacute;s&aacute;rl&aacute;s: Egy sikeres fizet&eacute;ssel z&aacute;rult folyamat, melynek k&ouml;vetkezt&eacute;ben a kos&aacute;rban l&eacute;vő Term&eacute;ket a V&aacute;s&aacute;rl&oacute; sikeresen kifizeti.</p>
<p class="p14">&nbsp;</p>
<p class="p7">V&aacute;s&aacute;rl&aacute;si tranzakci&oacute;: Egy sikeresen kifizetett Kos&aacute;r.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Vonalk&oacute;d: Vonalk&oacute;d vagy QR k&oacute;d.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Weboldal: A https://csakjegyek.hu c&iacute;men tal&aacute;lhat&oacute; oldal, illetve ennek aloldalai &eacute;s aldomain-je (pl. <a href="https://minta.csakjegyek.hu"><span class="s5">https://minta.csakjegyek.hu</span></a>)</p>
<p class="p14">&nbsp;</p>
<p class="p8">&nbsp;</p>
<p class="p16"><strong>II. Weboldal &eacute;s applik&aacute;ci&oacute; haszn&aacute;lati felt&eacute;telei &eacute;s szab&aacute;lyai</strong></p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p7">A Szolg&aacute;ltat&oacute; &aacute;ltal &uuml;zemeltetett Weboldalt &eacute;s Applik&aacute;ci&oacute;kat minden Felhaszn&aacute;l&oacute; saj&aacute;t felelőss&eacute;g&eacute;re haszn&aacute;lja. Felhaszn&aacute;l&oacute; az oldal haszn&aacute;lat&aacute;val elfogadja az Adatkezel&eacute;si Szab&aacute;lyzat &eacute;s jelen dokumentum rendelkez&eacute;seit.</p>
<p class="p14">&nbsp;</p>
<p class="p7">A Weboldalon &eacute;s Applik&aacute;ci&oacute;kban 14. &eacute;let&eacute;v&eacute;t be nem t&ouml;lt&ouml;tt term&eacute;szetes szem&eacute;ly nem hozhat l&eacute;tre Felhaszn&aacute;l&oacute;i Fi&oacute;kot &eacute;s ennek k&ouml;vetkezt&eacute;ben nem hajthat v&eacute;gre V&aacute;s&aacute;rl&aacute;st. A 14 &eacute;s 18 &eacute;v k&ouml;z&ouml;tti, korl&aacute;tozottan cselekvők&eacute;pes Felhaszn&aacute;l&oacute; kijelenti, hogy a Jegy vagy B&eacute;rlet megv&aacute;s&aacute;rl&aacute;sa olyan, a mindennapi &eacute;let szok&aacute;sos sz&uuml;ks&eacute;gleteinek fedez&eacute;se k&ouml;r&eacute;be tartoz&oacute; kisebb jelentős&eacute;gű szerződ&eacute;s, amelyhez nem sz&uuml;ks&eacute;ges t&ouml;rv&eacute;nyes k&eacute;pviselőj&eacute;nek k&ouml;zreműk&ouml;d&eacute;se &ndash; illetve a korl&aacute;tozottan cselekvők&eacute;pes Felhaszn&aacute;l&oacute; rendelkezik a t&ouml;rv&eacute;nyes k&eacute;pviselőj&eacute;nek a hozz&aacute;j&aacute;rul&aacute;s&aacute;val. A weboldal vagy applik&aacute;ci&oacute; Felhaszn&aacute;l&oacute;ja Felhaszn&aacute;l&oacute;i fi&oacute;k regisztr&aacute;l&aacute;sa eset&eacute;n elfogadja a Szolg&aacute;ltat&aacute;sra vonatkoz&oacute; szerződ&eacute;s felt&eacute;teleit, egyben jelen &Aacute;SZF rendelkez&eacute;seit. Felhaszn&aacute;l&oacute; a Felhaszn&aacute;l&oacute;i fi&oacute;k regisztr&aacute;l&aacute;sa eset&eacute;n egy bepip&aacute;lhat&oacute; mező seg&iacute;ts&eacute;g&eacute;vel kijelenti, hogy jelen &Aacute;SZF-ben foglalt felt&eacute;teleket, illetve az Adatkezel&eacute;si Szab&aacute;lyzatban foglaltak megismerte &eacute;s mag&aacute;ra n&eacute;zve k&ouml;telezőnek elfogadta, tov&aacute;bb&aacute; a Szolg&aacute;ltat&aacute;s ig&eacute;nybev&eacute;tel&eacute;hez sz&uuml;ks&eacute;ges adatai kezel&eacute;s&eacute;hez az &Aacute;SZF-ben valamint az Adatkezel&eacute;si Szab&aacute;lyzatban meghat&aacute;rozott k&ouml;rben hozz&aacute;j&aacute;rult.</p>
<p class="p8">&nbsp;</p>
<p class="p5"><strong>III. Felhaszn&aacute;l&oacute; jogai &eacute;s k&ouml;telezetts&eacute;gei</strong></p>
<p class="p13">&nbsp;</p>
<p class="p17">Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi, hogy az internet saj&aacute;toss&aacute;gai miatt a Weboldal &eacute;s Applik&aacute;ci&oacute;k folyamatos &uuml;zemeltet&eacute;se a Szolg&aacute;ltat&oacute; előzetes tudta &eacute;s sz&aacute;nd&eacute;ka ellen&eacute;re is megszakadhat. A Szolg&aacute;ltat&oacute; ennek megfelelően nem garant&aacute;lja a Szolg&aacute;ltat&aacute;s &eacute;s a kapcsol&oacute;d&oacute; Weboldal &eacute;s Applik&aacute;ci&oacute;k hibamentes &eacute;s zavartalan műk&ouml;d&eacute;s&eacute;t, illetőleg, hogy a Szolg&aacute;ltat&aacute;shoz val&oacute; hozz&aacute;f&eacute;r&eacute;s folyamatos vagy hibamentes lesz.</p>
<p class="p18">&nbsp;</p>
<p class="p19">Felhaszn&aacute;l&oacute; k&ouml;teles a V&aacute;s&aacute;rl&aacute;skor val&oacute;s &eacute;s t&eacute;nylegesen helyes sz&aacute;ml&aacute;z&aacute;si adatokat megadni. Szolg&aacute;ltat&oacute; val&oacute;tlan adatok megad&aacute;s&aacute;&eacute;rt felelőss&eacute;get nem v&aacute;llal.</p>
<p class="p20">&nbsp;</p>
<p class="p19">Felhaszn&aacute;l&oacute; k&ouml;teles a Felhaszn&aacute;l&oacute; fi&oacute;kj&aacute;nak regisztr&aacute;ci&oacute;ja sor&aacute;n saj&aacute;t e-mail c&iacute;m&eacute;t helyesen megadni. Felhaszn&aacute;l&oacute; a Felhaszn&aacute;l&oacute;i fi&oacute;kj&aacute;nak regisztr&aacute;ci&oacute;j&aacute;val elfogadja, hogy kiz&aacute;r&oacute;lag &eacute;s teljes k&ouml;rűen felelős a t&eacute;vesen megadott, hib&aacute;san megadott vagy elg&eacute;pelt e-mail c&iacute;m megad&aacute;s&aacute;b&oacute;l k&ouml;vetkező kellemetlens&eacute;gek&eacute;rt, k&aacute;rok&eacute;rt &eacute;s jogk&ouml;vetkezm&eacute;nyek&eacute;rt.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute; kiz&aacute;r&oacute;lagos felelőss&eacute;ge Felhaszn&aacute;l&oacute; fi&oacute;kj&aacute;nak regisztr&aacute;ci&oacute;ja sor&aacute;n (illetve minden tov&aacute;bbi m&oacute;dos&iacute;t&aacute;s sor&aacute;n), olyan, m&aacute;sok &aacute;ltal nem ismert &eacute;s m&aacute;s weboldalakon, applik&aacute;ci&oacute;kban nem haszn&aacute;lt jelsz&oacute;t megadni, mellyel illet&eacute;ktelen szem&eacute;ly nem tud hozz&aacute;f&eacute;rni Felhaszn&aacute;l&oacute;i fi&oacute;kj&aacute;hoz. Amennyiben tudom&aacute;s&aacute;ra jut, hogy jelszav&aacute;r&oacute;l m&aacute;sok is tudom&aacute;st szerezhettek, k&ouml;teles azt m&oacute;dos&iacute;tani a Weboldalon vagy az Applik&aacute;ci&oacute;kban. Ek&ouml;rben a Szolg&aacute;ltat&oacute; minden felelőss&eacute;g&eacute;t kiz&aacute;rja.</p>
<p class="p14">&nbsp;</p>
<p class="p21"><span class="s6">Felhaszn&aacute;l&oacute; elfogadja &eacute;s tudom&aacute;sul veszi, hogy Szolg&aacute;ltat&oacute; az online bankk&aacute;rty&aacute;s fizet&eacute;sek lebonyol&iacute;t&aacute;sa &eacute;rdek&eacute;ben k&uuml;lső, harmadik f&eacute;l Fizet&eacute;si szolg&aacute;ltat&oacute; seg&iacute;t&eacute;s&eacute;g&eacute;t veszi ig&eacute;nybe. Szolg&aacute;ltat&oacute; nem f&eacute;r hozz&aacute; a Felhaszn&aacute;l&oacute; &aacute;ltal a Fizet&eacute;si szolg&aacute;ltat&oacute; oldal&aacute;n megadott bankk&aacute;rtya adatokhoz. Szolg&aacute;ltat&oacute; nem felelős semmilyen olyan k&aacute;r&eacute;rt vagy vissza&eacute;l&eacute;s&eacute;rt, amely a bankk&aacute;rty&aacute;val t&ouml;rt&eacute;nő fizet&eacute;s sor&aacute;n vagy k&ouml;vetkezt&eacute;ben keletkezik.</span><span class="s3"> Ek&ouml;rben a Szolg&aacute;ltat&oacute; minden felelőss&eacute;g&eacute;t kiz&aacute;rja.</span></p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute; kiz&aacute;r&oacute;lagos felelőss&eacute;ge a Bel&eacute;pőjegyen &eacute;s B&eacute;rlet&eacute;n tal&aacute;lhat&oacute; vonalk&oacute;d biztons&aacute;gban tart&aacute;sa &eacute;s titkoss&aacute;g&aacute;nak megőrz&eacute;se. Felhaszn&aacute;l&oacute; elfogadja, hogy egy Esem&eacute;nyre egy vonalk&oacute;ddal kiz&aacute;r&oacute;lag egyszer lehet bel&eacute;pni, &iacute;gy abban az esetben ha egy illet&eacute;ktelen szem&eacute;ly a Felhaszn&aacute;l&oacute; sz&aacute;m&aacute;ra sikeres fizet&eacute;s ut&aacute;n ki&aacute;ll&iacute;tott vonalk&oacute;dot az esem&eacute;ny helysz&iacute;n&eacute;n kor&aacute;bban leolvas, &uacute;gy Felhaszn&aacute;l&oacute; nem lesz jogosult az Esem&eacute;nyen val&oacute; r&eacute;szv&eacute;telre, &eacute;s ezzel kapcsolatos k&aacute;rt&eacute;r&iacute;t&eacute;sre sem.</p>
<p class="p14">&nbsp;</p>
<p class="p21"><span class="s6">Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi, hogy a Bel&eacute;pőjegyen &eacute;s B&eacute;rleten felt&uuml;ntetett kezd&eacute;si időpont, helysz&iacute;n, &eacute;s egy&eacute;b adatok t&aacute;j&eacute;koztat&oacute; jellegűek, az Esem&eacute;ny t&eacute;nyleges kezd&eacute;si időpontja, helysz&iacute;ne &eacute;s egy&eacute;b param&eacute;terei ettől elt&eacute;rhetnek, melyről t&aacute;j&eacute;koztat&aacute;st nem a Szolg&aacute;ltat&oacute;, hanem az Esem&eacute;ny szervezője biztos&iacute;t. </span><span class="s3">Ek&ouml;rben a Szolg&aacute;ltat&oacute; minden felelőss&eacute;g&eacute;t kiz&aacute;rja.</span></p>
<p class="p18">&nbsp;</p>
<p class="p17">Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi, hogy az Esem&eacute;ny szervezője, illetve alv&aacute;llalkoz&oacute;i jogosultak ellenőrizni a Bel&eacute;pőjegy vagy B&eacute;rlet &eacute;rv&eacute;nyess&eacute;g&eacute;t az esem&eacute;nyre val&oacute; bel&eacute;p&eacute;s sor&aacute;n. Amennyiben a Bel&eacute;pőjegy vagy B&eacute;rlet s&eacute;r&uuml;lt, sz&aacute;nd&eacute;kosan rong&aacute;lt vagy a vonalk&oacute;dja nem leolvashat&oacute; vagy a vonalk&oacute;d m&aacute;r kor&aacute;bban fel lett haszn&aacute;lva vagy nem igazolt kedvezm&eacute;nyű a jegy, akkor a Megb&iacute;z&oacute;, a rendezv&eacute;ny szervezője, illetve alv&aacute;llalkoz&oacute;i megtagadhatj&aacute;k az Esem&eacute;nyre t&ouml;rt&eacute;nő bel&eacute;p&eacute;st. Az ilyen indokb&oacute;l t&ouml;rt&eacute;nő kiz&aacute;r&aacute;s eset&eacute;n a Bel&eacute;pőjeggyel vagy B&eacute;rlettel bel&eacute;pni k&iacute;v&aacute;nt szem&eacute;ly, illetve az azt megv&aacute;s&aacute;rl&oacute; Felhaszn&aacute;l&oacute; k&aacute;rt&eacute;r&iacute;t&eacute;sre nem jogosult.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Bel&eacute;pőjegyet vagy B&eacute;rletet megv&aacute;s&aacute;rl&oacute; Felhaszn&aacute;l&oacute;, illetve a Bel&eacute;pőjegyet vagy B&eacute;rletet bel&eacute;p&eacute;sre haszn&aacute;l&oacute; szem&eacute;ly k&ouml;teles t&aacute;j&eacute;koz&oacute;dni a rendezv&eacute;ny r&eacute;szv&eacute;teli &eacute;s biztons&aacute;gi felt&eacute;teleiről &eacute;s szab&aacute;lyair&oacute;l, illetve a B&eacute;rletek felt&eacute;teleiről. Amennyiben a szab&aacute;lyok nem betart&aacute;sa miatt a Megb&iacute;z&oacute;, annak alv&aacute;llalkoz&oacute;i vagy b&aacute;rmely hat&oacute;s&aacute;g megtagadja az esem&eacute;nyre val&oacute; bel&eacute;p&eacute;st vagy a Felhaszn&aacute;l&oacute; &eacute;s/vagy a jegy jogosultja z Esem&eacute;nyből kiz&aacute;r&aacute;sra ker&uuml;l, &uacute;gy a Bel&eacute;pőjeggyel vagy B&eacute;rlettel bel&eacute;pni k&iacute;v&aacute;nt vagy m&aacute;r bel&eacute;pett szem&eacute;ly, illetve az azt megv&aacute;s&aacute;rl&oacute; Felhaszn&aacute;l&oacute; k&aacute;rt&eacute;r&iacute;t&eacute;sre nem jogosult.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi &eacute;s elfogadja, hogy bizonyos Bel&eacute;pőjegyek &eacute;s B&eacute;rletek kiz&aacute;r&oacute;lag bizonyos szem&eacute;lyeket jogos&iacute;tanak bel&eacute;p&eacute;sre (&Aacute;SZF X. pontja &aacute;ltal tartalmazott t&aacute;bl&aacute;zat alapj&aacute;n) . A rendezv&eacute;ny szervezője, illetve alv&aacute;llalkoz&oacute;i jogosultak ellenőrizni a bel&eacute;pni k&iacute;v&aacute;nt szem&eacute;ly adott Bel&eacute;pőjegyre &eacute;s B&eacute;rletre vonatkoz&oacute; jogosults&aacute;g&aacute;t. Amennyiben az ellenőrz&eacute;st nem tudj&aacute;k elv&eacute;gezni, vagy az ellenőrz&eacute;s eredm&eacute;nye a jogosults&aacute;g hi&aacute;ny&aacute;nak meg&aacute;llap&iacute;t&aacute;sa, &uacute;gy a Megb&iacute;z&oacute;, a rendezv&eacute;ny szervezője vagy alv&aacute;llalkoz&oacute;i jogosultak a bel&eacute;pni k&iacute;v&aacute;nt szem&eacute;ly bel&eacute;p&eacute;st megtagadni. Ilyen esetben sem a bel&eacute;pni k&iacute;v&aacute;nt szem&eacute;ly, sem a Bel&eacute;pőjegyet vagy B&eacute;rletet megv&aacute;s&aacute;rl&oacute; Felhaszn&aacute;l&oacute; k&aacute;rt&eacute;r&iacute;t&eacute;sre nem jogosult.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; elfogadja, hogy a Bel&eacute;pőjegyek &eacute;s B&eacute;rletek az esem&eacute;ny ter&uuml;let&eacute;n is csak bizonyos, a jegyen/b&eacute;rleten felt&uuml;ntetett vagy a rendezv&eacute;nyen egy&eacute;bk&eacute;nt meghat&aacute;rozott ter&uuml;letek l&aacute;togat&aacute;s&aacute;ra jogos&iacute;tanak fel.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; k&ouml;teles minden megv&aacute;s&aacute;rolt Bel&eacute;pőjegy &eacute;s B&eacute;rlet ut&aacute;n megfizetni az aktu&aacute;lisan hat&aacute;lyos Szolg&aacute;ltat&aacute;si d&iacute;jat, melynek m&eacute;rt&eacute;k&eacute;t Bel&eacute;pőjegyenk&eacute;t &eacute;s B&eacute;rletenk&eacute;nt a Kos&aacute;rba helyez&eacute;s ut&aacute;n, de m&eacute;g a Fizet&eacute;s előtt egy&eacute;rtelműen azonos&iacute;that&oacute; m&oacute;don felt&uuml;ntet&eacute;sre ker&uuml;l.</p>
<p class="p18">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute; elfogadja, hogy a Bel&eacute;pőjegy vagy B&eacute;rlet &aacute;r&aacute;nak visszat&eacute;r&iacute;t&eacute;se eset&eacute;n sem jogosult a Szolg&aacute;ltat&aacute;si d&iacute;j visszat&eacute;r&iacute;t&eacute;s&eacute;re, illetve a Szolg&aacute;ltat&oacute; fel&eacute; ilyen ig&eacute;nnyel fell&eacute;pni.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi, hogy a Szolg&aacute;ltat&oacute; nem figyeli &eacute;s korl&aacute;tozza, hogy egy Felhaszn&aacute;l&oacute; vett-e olyan esem&eacute;nyre Bel&eacute;pőjegyet, melyet kor&aacute;bban vagy egyidőben v&aacute;s&aacute;rolt B&eacute;rlet&eacute;vel is l&aacute;togathatna. Ilyen esetben Felhaszn&aacute;l&oacute; el&aacute;ll&aacute;sra, visszat&eacute;r&iacute;t&eacute;sre vagy k&aacute;rt&eacute;r&iacute;t&eacute;sre nem jogosult.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; k&ouml;teles a megv&aacute;s&aacute;rolt, Esem&eacute;nyre &eacute;rv&eacute;nyes Bel&eacute;pőjegy&eacute;t vagy B&eacute;rlet&eacute;t olyan &aacute;llapotban mag&aacute;val hozni, hogy az a bel&eacute;p&eacute;s sor&aacute;n bemutat&aacute;sra, &eacute;s leolvas&aacute;sra alkalmas legyen a Megb&iacute;z&oacute; vagy a rendezv&eacute;ny szervezője &aacute;ltal meghat&aacute;rozott m&oacute;don &eacute;s form&aacute;ban.</p>
<p class="p18">&nbsp;</p>
<p class="p17">A Felhaszn&aacute;l&oacute; nem jogosult a Bel&eacute;pőjegyet vagy B&eacute;rletet tov&aacute;bb &eacute;rt&eacute;kes&iacute;teni. Az kifejezetten tilos. Ilyen esetben a Megb&iacute;z&oacute; vagy annak alv&aacute;llalkoz&oacute;ja jogosult az &eacute;rintett Bel&eacute;pőjegyet vagy B&eacute;rletet bevonni, annak felhaszn&aacute;l&aacute;s&aacute;t ellen&eacute;rt&eacute;k fizet&eacute;se n&eacute;lk&uuml;l megtagadni. Emiatt a Felhaszn&aacute;l&oacute; ig&eacute;nnyel nem &eacute;lhet sem a Szolg&aacute;ltat&oacute;, sem a Megb&iacute;z&oacute; vagy az Esem&eacute;ny szervezőj&eacute;vel szemben sem.</p>
<p class="p8">&nbsp;</p>
<p class="p5"><strong>IV. Szolg&aacute;ltat&oacute; jogai &eacute;s k&ouml;telezetts&eacute;gei</strong></p>
<p class="p13">&nbsp;</p>
<p class="p7">A Szolg&aacute;ltat&oacute; k&ouml;zvet&iacute;tők&eacute;nt j&aacute;r el, a Bel&eacute;pőjegy &eacute;rt&eacute;kes&iacute;t&eacute;sben &eacute;s B&eacute;rlet &eacute;rt&eacute;kes&iacute;t&eacute;sben k&ouml;zvet&iacute;tett szolg&aacute;ltat&aacute;sk&eacute;nt k&ouml;zreműk&ouml;dve. A Szolg&aacute;ltat&oacute; az Esem&eacute;ny szervez&eacute;s&eacute;ben &eacute;s lebonyol&iacute;t&aacute;s&aacute;ban nem vesz r&eacute;szt, tev&eacute;kenys&eacute;ge &eacute;s felelőss&eacute;ge kiz&aacute;r&oacute;lag a Bel&eacute;pőjegyek &eacute;s B&eacute;rletek &eacute;rt&eacute;kes&iacute;t&eacute;s&eacute;re korl&aacute;toz&oacute;dik. A meghirdetett Esem&eacute;ny megfelelő minős&eacute;gű, le&iacute;r&aacute;snak megfelelő lebonyol&iacute;t&aacute;sa a Megb&iacute;z&oacute; kiz&aacute;r&oacute;lagos k&ouml;telezetts&eacute;ge &eacute;s felelőss&eacute;ge. A Szolg&aacute;ltat&oacute; nem v&aacute;llal semmilyen felelőss&eacute;get a megv&aacute;s&aacute;rolt Bel&eacute;pőjeggyel vagy B&eacute;rlettel l&aacute;togatott Esem&eacute;ny minős&eacute;ge, lebonyol&iacute;t&aacute;sa, megval&oacute;sul&aacute;sa vagy elmarad&aacute;sa kapcs&aacute;n. A Bel&eacute;pőjegy vagy B&eacute;rlet megv&aacute;s&aacute;rl&aacute;s&aacute;n t&uacute;lmenő szolg&aacute;ltat&aacute;s tekintet&eacute;ben a Felhaszn&aacute;l&oacute; a Megb&iacute;z&oacute;val ker&uuml;l szolg&aacute;ltat&aacute;si jogviszonyba.</p>
<p class="p14">&nbsp;</p>
<p class="p17">A Szolg&aacute;ltat&oacute; jogosult a Weboldal &eacute;s Applik&aacute;ci&oacute;k karbantart&aacute;sa &eacute;s fejleszt&eacute;se &eacute;rdek&eacute;ben a Weboldal &eacute;s Applik&aacute;ci&oacute;k el&eacute;r&eacute;s&eacute;t korl&aacute;tozni, a Jegy &eacute;s B&eacute;rlet&eacute;rt&eacute;kes&iacute;t&eacute;si Szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;t r&eacute;szlegesen vagy teljes eg&eacute;sz&eacute;ben sz&uuml;neteltetni minden előzetes t&aacute;j&eacute;koztat&aacute;s vagy &eacute;rtes&iacute;t&eacute;s n&eacute;lk&uuml;l.</p>
<p class="p18">&nbsp;</p>
<p class="p7">Szolg&aacute;ltat&oacute; minden tőle telhetőt megtesz annak &eacute;rdek&eacute;ben, hogy a Felhaszn&aacute;l&oacute; &aacute;ltal megadott szem&eacute;lyes &eacute;s nem szem&eacute;lyes adatok biztons&aacute;gban legyenek. Szolg&aacute;ltat&oacute; &aacute;ltal &uuml;zemeltetett Weboldalon &eacute;s Applik&aacute;ci&oacute;kban ennek &eacute;rdek&eacute;ben kiz&aacute;r&oacute;lag olyan jelsz&oacute;val lehet Felhaszn&aacute;l&oacute;i fi&oacute;kot Regisztr&aacute;lni, mely legal&aacute;bb 8 karakterből &aacute;ll, illetve tartalmaz legal&aacute;bb egy kisbetűt, legal&aacute;bb egy nagybetűt &eacute;s legal&aacute;bb egy sz&aacute;mot.</p>
<p class="p14">&nbsp;</p>
<p class="p17">A Szolg&aacute;ltat&oacute; csak a neki minden k&eacute;ts&eacute;get kiz&aacute;r&oacute;an felr&oacute;hat&oacute; sz&aacute;nd&eacute;kos vagy s&uacute;lyosan gondatlan hib&aacute;kkal k&ouml;zvetlen&uuml;l okozott k&aacute;rok&eacute;rt felel. A felelőss&eacute;g m&eacute;rt&eacute;ke nem haladhatja meg a V&aacute;s&aacute;rl&aacute;si tranzakci&oacute; &eacute;rt&eacute;k&eacute;t, illetve kiz&aacute;r minden k&ouml;vetkezm&eacute;nyi k&aacute;rt is.</p>
<p class="p14">&nbsp;</p>
<p class="p7">A Szolg&aacute;ltat&oacute; vagy a Megb&iacute;z&oacute; megk&ouml;vetelhetik, hogy bizonyos Esem&eacute;nyre &eacute;rt&eacute;kes&iacute;tett bizonyos sz&eacute;khelyre kiz&aacute;r&oacute;lag akkor van lehetős&eacute;g Jegyet vagy B&eacute;rletet v&aacute;s&aacute;rolni, ha Felhaszn&aacute;l&oacute; megadja a Jegy tulajdonos&aacute;nak adatait. A k&ouml;telezően &eacute;s opcion&aacute;lis megadand&oacute; Jegytulajdonosi adatok Esem&eacute;nyenk&eacute;nt &eacute;s sz&eacute;kenk&eacute;nt v&aacute;ltozhatnak. Felhaszn&aacute;l&oacute; kiz&aacute;r&oacute;lagos felelőss&eacute;ge val&oacute;s&aacute;gnak megfelelő tulajdonosi adatokat rendelni Jegyekhez &eacute;s B&eacute;rletekhez. A t&eacute;vesen vagy hib&aacute;san megadott Jegytulajdonosi adatokb&oacute;l k&ouml;vetkező kellemetlens&eacute;gek&eacute;rt, k&aacute;rok&eacute;rt &eacute;s jogk&ouml;vetkezm&eacute;nyek&eacute;rt Felhaszn&aacute;l&oacute; kiz&aacute;r&oacute;lagosan felelős.</p>
<p class="p14">&nbsp;</p>
<p class="p7">A Szolg&aacute;ltat&oacute; v&aacute;llalja, hogy Felhaszn&aacute;l&oacute; &aacute;ltal kifizetett, vonalk&oacute;dot tartalmaz&oacute; Bel&eacute;pőjegyet vagy B&eacute;rletet kiz&aacute;r&oacute;lag a Felhaszn&aacute;l&oacute; &aacute;ltal regisztr&aacute;ci&oacute;kor megadott e-mail c&iacute;mre k&eacute;zbes&iacute;ti, valamint a V&aacute;s&aacute;rl&oacute; jelsz&oacute;val v&eacute;dett Felhaszn&aacute;l&oacute;i Fi&oacute;kj&aacute;ban teszi k&ouml;zz&eacute;. Ezenk&iacute;v&uuml;l amennyiben az Esem&eacute;nyen bel&eacute;ptető rendszert haszn&aacute;lnak, Szolg&aacute;ltat&oacute; a szerződ&eacute;s teljes&iacute;t&eacute;s&eacute;nek &eacute;rdek&eacute;ben tov&aacute;bb&iacute;tja Megb&iacute;z&oacute; &aacute;ltal kijel&ouml;lt alv&aacute;llalkoz&oacute;nak a vonalk&oacute;dot is tartalmaz&oacute; Bel&eacute;pőjegyet &eacute;s B&eacute;rletet.</p>
<p class="p14">&nbsp;</p>
<p class="p17">Amennyiben az Esem&eacute;ny vagy B&eacute;rlet &eacute;rt&eacute;kes&iacute;t&eacute;s&eacute;ben, azok tulajdons&aacute;g&aacute;ban vagy felhaszn&aacute;lhat&oacute;s&aacute;g&aacute;ban v&aacute;ltoz&aacute;s t&ouml;rt&eacute;nik, arr&oacute;l Felhaszn&aacute;l&oacute;t a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezője t&aacute;j&eacute;koztatja. A Szolg&aacute;ltat&oacute; mindent megtesz a t&aacute;j&eacute;koztat&aacute;s előseg&iacute;t&eacute;se &eacute;rdek&eacute;ben, azonban nem felelős az Esem&eacute;nyhez kapcsol&oacute;d nem megfelelő adatokkal, inform&aacute;ci&oacute;kkal, tartalmakkal kapcsolatban. Ezeket minden esetben a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezője r&ouml;gz&iacute;ti, &iacute;gy a kiz&aacute;r&oacute;lagos felelőss&eacute;g is a Megb&iacute;z&oacute;t &eacute;s/vagy az Esem&eacute;ny szervezőj&eacute;t terheli.</p>
<p class="p18">&nbsp;</p>
<p class="p17">Szolg&aacute;ltat&oacute; jelen &Aacute;SZF X. pontj&aacute;ban r&eacute;szletezi, hogy bizonyos Bel&eacute;pőjegyek &eacute;s B&eacute;rletek csak bizonyos Felhaszn&aacute;l&oacute;kat jogos&iacute;tanak fel a bel&eacute;p&eacute;sre (t&ouml;bbek k&ouml;z&ouml;tt kisgyermek jegy, gyermek jegy, di&aacute;k jegy, nyugd&iacute;jas jegy) A jogosults&aacute;g megl&eacute;t&eacute;t a Szolg&aacute;ltat&oacute; a v&aacute;s&aacute;rl&aacute;skor nem vizsg&aacute;lja. A rendezv&eacute;ny szervezője saj&aacute;t maga vagy alv&aacute;llalkoz&oacute;in kereszt&uuml;l jogosult annak ellenőrz&eacute;s&eacute;re, hogy a Bel&eacute;pőjegy vagy B&eacute;rlet felmutat&oacute;ja jogosult-e az adott t&iacute;pus&uacute; Bel&eacute;pőjegy vagy B&eacute;rlet haszn&aacute;lat&aacute;ra. A bel&eacute;p&eacute;s mindaddig megtagadhat&oacute;, am&iacute;g a haszn&aacute;lat jogosults&aacute;g&aacute;t a Bel&eacute;pőjegy vagy B&eacute;rlet felmutat&oacute;ja nem igazolja. Ilyen esetben sem a bel&eacute;pni k&iacute;v&aacute;nt szem&eacute;ly, sem a Bel&eacute;pőjegyet vagy B&eacute;rletet megv&aacute;s&aacute;rl&oacute; Felhaszn&aacute;l&oacute; k&aacute;rt&eacute;r&iacute;t&eacute;sre nem jogosult, a Szolg&aacute;ltat&oacute; a felelőss&eacute;g&eacute;t kiz&aacute;rja.</p>
<p class="p18">&nbsp;</p>
<p class="p19">Szolg&aacute;ltat&oacute; alv&aacute;llalkoz&oacute; &eacute;s egy&eacute;b k&ouml;zreműk&ouml;dő ig&eacute;nybev&eacute;tel&eacute;re jogosult.</p>
<p class="p20">&nbsp;</p>
<p class="p7">Szolg&aacute;ltat&oacute; jogosult egyes szem&eacute;lyek, vagy ak&aacute;r eg&eacute;sz orsz&aacute;gok sz&aacute;m&aacute;ra korl&aacute;tozni a Weboldal &eacute;s Applik&aacute;ci&oacute;k el&eacute;r&eacute;s&eacute;t. Vissza&eacute;l&eacute;s vagy annak gyan&uacute;ja, illetve a jelen &Aacute;SZF-ben foglaltak megs&eacute;rt&eacute;se eset&eacute;n a Szolg&aacute;ltat&oacute; jogosult a hozz&aacute;f&eacute;r&eacute;st korl&aacute;tozni, &eacute;s/vagy a regisztr&aacute;ci&oacute;t t&ouml;r&ouml;lni. A regisztr&aacute;ci&oacute; t&ouml;rl&eacute;se eset&eacute;n is jogosult a Szolg&aacute;ltat&oacute; a jogszab&aacute;lyokban meghat&aacute;rozott ideig kezelni a Felhaszn&aacute;l&oacute; &aacute;ltal megadott adatokat &eacute;s tev&eacute;kenys&eacute;gnapl&oacute;t jogszab&aacute;lyi k&ouml;telezetts&eacute;geinek teljes&iacute;t&eacute;se, valamint &uuml;zleti jogainak biztos&iacute;t&aacute;sa &eacute;rdek&eacute;ben.</p>
<p class="p14">&nbsp;</p>
<p class="p22">&nbsp;</p>
<p class="p23">&nbsp;</p>
<p class="p24"><strong>V. Regisztr&aacute;ci&oacute; &eacute;s Bejelentkez&eacute;s</strong><span class="s2"><br /></span></p>
<p class="p7">Felhaszn&aacute;l&oacute;i fi&oacute;kot regisztr&aacute;lni e-mail c&iacute;m, vezet&eacute;kn&eacute;v, keresztn&eacute;v &eacute;s jelsz&oacute; megad&aacute;s&aacute;val lehet, abban az esetben ha a Felhaszn&aacute;l&oacute; elfogadja az Adatv&eacute;delmi Szab&aacute;lyzatot &eacute;s az &Aacute;SZF-et.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute;i fi&oacute;kba bejelentkezni egy kor&aacute;bban regisztr&aacute;lt e-mail c&iacute;m, &eacute;s a hozz&aacute; regisztr&aacute;ci&oacute;kor vagy jelsz&oacute; m&oacute;dos&iacute;t&aacute;skor megadott jelsz&oacute;val lehet. A Szolg&aacute;ltat&oacute; jogosult Felhaszn&aacute;l&oacute;i vissza&eacute;l&eacute;s vagy annak gyan&uacute;ja vagy az &Aacute;SZF-et s&eacute;rtő haszn&aacute;lat eset&eacute;n korl&aacute;tozni egy-egy Felhaszn&aacute;l&oacute;i fi&oacute;khoz t&ouml;rt&eacute;nő hozz&aacute;f&eacute;r&eacute;st, valamint egy-egy eszk&ouml;zről t&ouml;rt&eacute;nő b&aacute;rmelyik Felhaszn&aacute;l&oacute;i fi&oacute;kba t&ouml;rt&eacute;nő bejelentkez&eacute;st. Jogosult tov&aacute;bb&aacute; a regisztr&aacute;ci&oacute;t t&ouml;r&ouml;lni.</p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p25"><strong>VI. Jegyv&aacute;s&aacute;rl&aacute;s &eacute;s B&eacute;rletv&aacute;s&aacute;rl&aacute;si folyamat</strong></p>
<p class="p26">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute; a főoldalon, illetve k&uuml;l&ouml;nb&ouml;ző &aacute;loldalakon tekintheti meg azoknak az Esem&eacute;nyeknek list&aacute;j&aacute;t melyekre Bel&eacute;pőjegyet vagy B&eacute;rletet tud v&aacute;s&aacute;rolni.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Felhaszn&aacute;l&oacute; a megv&aacute;s&aacute;rolni k&iacute;v&aacute;nt Bel&eacute;pőjegy &eacute;s B&eacute;rlet jellemzőiről (Jegy&aacute;r, B&eacute;rlet &aacute;r, Szolg&aacute;ltat&aacute;si d&iacute;j, sz&eacute;k lok&aacute;ci&oacute;ja, Jegyt&iacute;pusa, B&eacute;rlet t&iacute;pusa, Esem&eacute;ny adatai), a Szolg&aacute;ltat&oacute; weboldal&aacute;n &eacute;s applik&aacute;ci&oacute;iban egy kiv&aacute;lasztott Esem&eacute;ny vagy B&eacute;rlet aloldal&aacute;n t&aacute;j&eacute;koz&oacute;dhat.<br /><br />Jegyt&eacute;rk&eacute;pes &eacute;rt&eacute;kes&iacute;t&eacute;s eset&eacute;n a Felhaszn&aacute;l&oacute; a Bel&eacute;pőjegyet vagy B&eacute;rletet a Kosar&aacute;ba helyezni a k&ouml;vetkező m&oacute;don tudja: Kattintson egy sz&uuml;rke sz&iacute;nű szektorra, majd a megjelenő sz&eacute;kek k&ouml;z&uuml;l kattintson egy feh&eacute;rre (#FFFFFF). Amennyiben sikeresen a Kosar&aacute;ba helyezte a jegyet, akkor z&ouml;ld sz&iacute;nűre fog v&aacute;ltani a lefoglalt sz&eacute;k. A m&aacute;sok &aacute;ltal Megv&aacute;s&aacute;rolt / Lefoglalt sz&eacute;keket piros sz&iacute;nnel jelezi a Jegyrendszer a t&eacute;rk&eacute;pen a Felhaszn&aacute;l&oacute;k sz&aacute;m&aacute;ra. Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi &eacute;s elfogadja, hogy az egyes jegyek/b&eacute;rletek kos&aacute;rba helyez&eacute;se, nem eredm&eacute;nyezi a jegyek / b&eacute;rletek megv&aacute;s&aacute;rl&aacute;s&aacute;t, ahhoz a v&aacute;s&aacute;rl&aacute;si folyamat v&eacute;g&eacute;n sikeres fizet&eacute;sre van sz&uuml;ks&eacute;g.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Jegyt&eacute;rk&eacute;p n&eacute;lk&uuml;li &eacute;rt&eacute;kes&iacute;t&eacute;s eset&eacute;n a Felhaszn&aacute;l&oacute; a Bel&eacute;pőjegyet vagy B&eacute;rletet a Kosar&aacute;ba helyezni a k&ouml;vetkező m&oacute;don tudja: Kattintson a plusz jelre. Amennyiben sikeresen a Kosar&aacute;ba helyezte a jegyet, akkor a Bel&eacute;pőjegy/B&eacute;rlet sz&aacute;ml&aacute;l&oacute;ban l&aacute;that&oacute; &eacute;rt&eacute;k n&ouml;vekedni fog a kattint&aacute;sok sz&aacute;m&aacute;val.</p>
<p class="p14">&nbsp;</p>
<p class="p7">A Kos&aacute;rban tal&aacute;lhat&oacute; Bel&eacute;pőjegyeket &eacute;s B&eacute;rleteket a Kos&aacute;r oldalon lehet megtekinteni. A Kos&aacute;r oldalon lehetős&eacute;g van a Bel&eacute;pőjegyekhez &eacute;s B&eacute;rletekhez tulajdonosi adatokat rendelni (bizonyos Esem&eacute;nyek &eacute;s B&eacute;rletek eset&eacute;n ez k&ouml;telező!), lehetős&eacute;g van Bel&eacute;pőjegyet &eacute;s B&eacute;rletet elt&aacute;vol&iacute;tani a kos&aacute;rb&oacute;l, valamint a Felhaszn&aacute;l&oacute;nak lehetős&eacute;ge van m&oacute;dos&iacute;tani kor&aacute;bban megadott sz&aacute;ml&aacute;z&aacute;si adatait.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Fizet&eacute;st kezdem&eacute;nyezni a Kos&aacute;r oldalon a Fizet&eacute;s gombra kattintva lehet.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Fizet&eacute;s előtt k&ouml;telező a Felhaszn&aacute;l&oacute;nak bejelentkezni a Felhaszn&aacute;l&oacute;i fi&oacute;kj&aacute;ba, kit&ouml;lteni sz&aacute;ml&aacute;z&aacute;si adatait, valamint kiv&aacute;lasztani a haszn&aacute;lni k&iacute;v&aacute;nt Fizet&eacute;si szolg&aacute;ltat&oacute;t. Fizet&eacute;si szolg&aacute;ltat&oacute;t abban az esetben is k&ouml;telező v&aacute;lasztani, ha csak egyetlen el&eacute;rhető opci&oacute;ra van lehetős&eacute;g!</p>
<p class="p14">&nbsp;</p>
<p class="p7">Kiz&aacute;r&oacute;lag olyan Kos&aacute;r fizet&eacute;s&eacute;re van lehetős&eacute;g amely legal&aacute;bb 1 darab Jegyet vagy B&eacute;rletet tartalmaz, &eacute;s hozz&aacute; van rendelve egy regisztr&aacute;lt Felhaszn&aacute;l&oacute;hoz.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Amennyiben a Fizet&eacute;si szolg&aacute;ltat&oacute; azt a visszajelz&eacute;st k&uuml;ldi a Jegyrendszer fel&eacute;, hogy a fizet&eacute;s sikeres volt, &uacute;gy a Jegyrendszer automatikusan ki&aacute;ll&iacute;t kifizetett Jegyenk&eacute;nt &eacute;s B&eacute;rletenk&eacute;nt egy darab, azonos vonak&oacute;ddal ell&aacute;tott<span class="Apple-converted-space">&nbsp; </span>PDF jegyet &eacute;s E-Jegyet. Ezenk&iacute;v&uuml;l a rendszer sz&aacute;ml&aacute;t &aacute;ll&iacute;t ki a Felhaszn&aacute;l&oacute; &aacute;ltal megadott sz&aacute;ml&aacute;z&aacute;si adatokkal, melyet a Felhaszn&aacute;l&oacute;i Fi&oacute;kon bel&uuml;l a "Jegyeim&rdquo; men&uuml;pontban lehet megtekinteni &eacute;s let&ouml;lteni.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Egy darab Bel&eacute;pőjegyhez a Jegyrendszer 1 (egy) darab, egyszeri bel&eacute;p&eacute;sre jogos&iacute;t&oacute; vonalk&oacute;dot gener&aacute;l.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Egy darab B&eacute;rlethez a Jegyrendszer 1 (egy) darab, egyszeri bel&eacute;p&eacute;sre jogos&iacute;t&oacute; vonalk&oacute;dot gener&aacute;l.</p>
<p class="p14">&nbsp;</p>
<p class="p7">Szolg&aacute;ltat&oacute; jogszab&aacute;lyi k&ouml;telezetts&eacute;gek miatt, a sportt&ouml;rv&eacute;nyben foglaltakkal &ouml;sszhangban adatot tov&aacute;bb&iacute;that az ott megszabott hat&oacute;s&aacute;g fel&eacute;, valamint Szolg&aacute;ltat&oacute;n&aacute;l megadott adatokhoz Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja is hozz&aacute;f&eacute;rhet.</p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p24"><strong>VII. Bel&eacute;pőjegyek &eacute;s b&eacute;rletek visszav&aacute;lt&aacute;sa</strong><span class="s2"><br /></span></p>
<p class="p27">A Bel&eacute;pőjegy visszav&aacute;lt&aacute;s&aacute;t csak &eacute;s kiz&aacute;r&oacute;lag elmaradt Esem&eacute;ny eset&eacute;n lehetet ig&eacute;nyelni, melynek menet&eacute;ről &eacute;s rendj&eacute;ről minden esetben a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezője d&ouml;nt, &eacute;s ez kiz&aacute;r&oacute;lag a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezőj&eacute;nek felelőss&eacute;ge. A Szolg&aacute;ltat&oacute; ek&ouml;rben felelőss&eacute;g&eacute;t kiz&aacute;rja.</p>
<p class="p27">A B&eacute;rlet visszav&aacute;lt&aacute;s&aacute;nak rendj&eacute;ről &eacute;s lehetős&eacute;g&eacute;ről minden esetben a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezője d&ouml;nt, &eacute;s ez kiz&aacute;r&oacute;lag a Megb&iacute;z&oacute; &eacute;s/vagy az Esem&eacute;ny szervezőj&eacute;nek felelőss&eacute;ge. A Szolg&aacute;ltat&oacute; ek&ouml;rben felelőss&eacute;g&eacute;t kiz&aacute;rja. Szolg&aacute;ltat&oacute; csak abban az esetben t&eacute;r&iacute;ti vissza a Bel&eacute;pőjegyet / B&eacute;rletet, ha erre az Esem&eacute;ny szervezője felhatalmaz&aacute;st ad.<br /><br />Visszet&eacute;r&iacute;t&eacute;ssel kapcsolatos ig&eacute;ny&eacute;t minden esetben az Esem&eacute;ny szervezője, illetve a B&eacute;rlet kibocs&aacute;t&oacute;ja fel&eacute; jelezze, melynek nev&eacute;t az A4-es form&aacute;tum&uacute; b&eacute;rlet alj&aacute;n tal&aacute;lja.</p>
<p class="p27">A Felhaszn&aacute;l&oacute; tudom&aacute;sul veszi, hogy a Bel&eacute;pőjegy &eacute;s B&eacute;rlet v&aacute;s&aacute;rl&aacute;sa sor&aacute;n felsz&aacute;m&iacute;tott Szolg&aacute;ltat&aacute;si d&iacute;j jegyvisszav&aacute;lt&aacute;s eset&eacute;n se ker&uuml;l visszat&eacute;r&iacute;t&eacute;sre.</p>
<p class="p28">&nbsp;</p>
<p class="p24"><strong>VIII. Applik&aacute;ci&oacute;k</strong><span class="s2"><br /></span></p>
<p class="p28">&nbsp;</p>
<p class="p27">Szolg&aacute;ltat&oacute; &aacute;ltal fejlesztett Applik&aacute;ci&oacute;k ingyenesen t&ouml;lthetőek le az Apple App Store &eacute;s Google Play Store-b&oacute;l, azonban bizonyos funkci&oacute;i Felhaszn&aacute;l&oacute;i fi&oacute;k l&eacute;trehoz&aacute;s&aacute;hoz k&ouml;t&ouml;ttek.</p>
<p class="p27">A Szolg&aacute;ltat&oacute; &aacute;ltal fejlesztett &eacute;s felt&ouml;lt&ouml;tt Applik&aacute;ci&oacute;kban megjelenő tartalom helyess&eacute;g&eacute;r&eacute;t, jogtisztas&aacute;g&aacute;&eacute;rt kiz&aacute;r&oacute;lag Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja felel. Szolg&aacute;ltat&oacute; ennek kapcs&aacute;n felelőss&eacute;gre nem vonhat&oacute;.</p>
<p class="p27">A Szolg&aacute;ltat&oacute; kiz&aacute;r&oacute;lag az applik&aacute;ci&oacute; IT infrastrukt&uacute;r&aacute;j&aacute;t &eacute;s h&aacute;tter&eacute;t biztos&iacute;tja, az Applik&aacute;ci&oacute;kban megjelenő j&aacute;t&eacute;kokon &eacute;s nyerem&eacute;nyj&aacute;t&eacute;kokon val&oacute; r&eacute;szv&eacute;tel felt&eacute;teleit Szolg&aacute;ltat&oacute; Megb&iacute;z&oacute;ja hat&aacute;rozza meg, &eacute;s kiz&aacute;r&oacute;lagosan felelős azok jogszab&aacute;lyok szerinti lebonyol&iacute;t&aacute;s&aacute;&eacute;rt.</p>
<p class="p27">A Szolg&aacute;ltat&oacute; kiz&aacute;rja felelőss&eacute;g&eacute;t minden, az Applik&aacute;ci&oacute;k haszn&aacute;lata sor&aacute;n a Felhaszn&aacute;l&oacute;n&aacute;l bek&ouml;vetkező k&aacute;rok&eacute;rt.</p>
<p class="p28">&nbsp;</p>
<p class="p24"><strong>IX. Panaszkezel&eacute;s, Egy&eacute;b rendelkez&eacute;sek</strong></p>
<p class="p14">&nbsp;</p>
<p class="p14">&nbsp;</p>
<p class="p17">A l&eacute;trej&ouml;tt szerződ&eacute;s &iacute;r&aacute;sbeli szerződ&eacute;snek minős&uuml;l. A szerződ&eacute;s l&eacute;trej&ouml;tt&eacute;t a Jegyrendszerben t&aacute;rolt v&aacute;s&aacute;rl&aacute;si adatok igazolj&aacute;k.</p>
<p class="p18">&nbsp;</p>
<p class="p21"><span class="s6">A Felek k&ouml;z&ouml;tti szerződ&eacute;s magyar nyelven j&ouml;n l&eacute;tre. A Szolg&aacute;ltat&oacute; t&aacute;j&eacute;koztatja Felhaszn&aacute;l&oacute;t, hogy a Szolg&aacute;ltat&oacute; jogosult az &Aacute;SZF-et egyoldal&uacute;an m&oacute;dos&iacute;tani. Az &Aacute;SZF m&oacute;dos&iacute;t&aacute;s&aacute;nak eset&eacute;n a Szolg&aacute;ltat&oacute; a Felhaszn&aacute;l&oacute;t a v&aacute;ltoz&aacute;sok weboldalon &eacute;s applik&aacute;ci&oacute;kban t&ouml;rt&eacute;nő k&ouml;zz&eacute;t&eacute;tele &uacute;tj&aacute;n &eacute;rtes&iacute;ti, a m&oacute;dos&iacute;t&aacute;s hat&aacute;lyba l&eacute;p&eacute;s&eacute;t megelőzően legal&aacute;bb 15 nappal. A weboldal &eacute;s/vagy az applik&aacute;ci&oacute; tov&aacute;bbi haszn&aacute;lata eset&eacute;n a Felhaszn&aacute;l&oacute; a m&oacute;dos&iacute;t&aacute;st elfogadottnak tekinti.</span> <span class="s6">Amennyiben a Felhaszn&aacute;l&oacute; nem fogadja el a m&oacute;dos&iacute;tott &Aacute;SZF-et, &uacute;gy sz&aacute;m&aacute;ra a Szolg&aacute;ltat&oacute; korl&aacute;tozhatja vagy megtagadhatja a szolg&aacute;ltat&aacute;s el&eacute;r&eacute;s&eacute;t &eacute;s tov&aacute;bbi haszn&aacute;lat&aacute;t.</span></p>
<p class="p18">&nbsp;</p>
<p class="p17">Felhaszn&aacute;l&oacute;i panasz eset&eacute;n a Felhaszn&aacute;l&oacute; jogosult a jelen &Aacute;SZF legelej&eacute;n megjel&ouml;lt e-mail c&iacute;men felvenni a kapcsolatot a Szolg&aacute;ltat&oacute;val. A Szolg&aacute;ltat&oacute; v&aacute;llalja, hogy a Felhaszn&aacute;l&oacute;i panaszt halad&eacute;ktalanul, de legk&eacute;sőbb 8 napt&aacute;ri napon bel&uuml;l kivizsg&aacute;lja &eacute;s a Felhaszn&aacute;l&oacute; r&eacute;sz&eacute;re e-mailben visszajelez.</p>
<p class="p18">&nbsp;</p>
<p class="p17">Amennyiben a panaszkezel&eacute;s nem vezet eredm&eacute;nyre, &uacute;gy a Felhaszn&aacute;l&oacute; jogosult a Budapesti B&eacute;k&eacute;ltető Test&uuml;lethez fordulni &eacute;s b&eacute;k&eacute;ltető elj&aacute;r&aacute;st kezdem&eacute;nyezni (<a href="https://bekeltet.bkik.hu/"><span class="s4">https://bekeltet.bkik.hu/</span></a>).</p>
<p class="p28">&nbsp;</p>
<p class="p24"><strong>X. Jegy &eacute;s b&eacute;rlet t&iacute;pusokra vonatkoz&oacute; szab&aacute;lyok</strong></p>
<p class="p29">&nbsp;</p>
<table class="t1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="td1" valign="top">
<p class="p30"><strong>Jegy vagy b&eacute;rlet t&iacute;pusa</strong></p>
</td>
<td class="td1" valign="top">
<p class="p31"><strong>Adott jegy vagy b&eacute;rlet t&iacute;pussal az Esem&eacute;nyre bel&eacute;p&eacute;sre jogosult szem&eacute;lyek k&ouml;re</strong></p>
</td>
</tr>
<tr>
<td class="td2" valign="top">
<p class="p31">Felnőtt jegy</p>
</td>
<td class="td2" valign="top">
<p class="p31">B&aacute;rmely szem&eacute;ly aki nincs eltiltva az esem&eacute;ny l&aacute;togat&aacute;s&aacute;t&oacute;l.</p>
</td>
</tr>
<tr>
<td class="td3" valign="top">
<p class="p31">Nyugd&iacute;jas jegy</p>
</td>
<td class="td3" valign="top">
<p class="p31">65. &eacute;let&eacute;v&eacute;t bet&ouml;lt&ouml;tt szem&eacute;ly</p>
</td>
</tr>
<tr>
<td class="td4" valign="top">
<p class="p31">Gyermek jegy</p>
</td>
<td class="td4" valign="top">
<p class="p31">14. &eacute;let&eacute;v&eacute;t m&eacute;g be nem t&ouml;lt&ouml;tte szem&eacute;ly</p>
</td>
</tr>
<tr>
<td class="td5" valign="top">
<p class="p31">Di&aacute;k jegy</p>
</td>
<td class="td5" valign="top">
<p class="p31">14. &eacute;let&eacute;v&eacute;t m&aacute;r bet&ouml;lt&ouml;tt szem&eacute;ly, aki rendelkezik &eacute;rv&eacute;nyes di&aacute;kigazolv&aacute;nnyal</p>
</td>
</tr>
<tr>
<td class="td5" valign="top">
<p class="p31">Mozg&aacute;skorl&aacute;tozott jegy</p>
</td>
<td class="td5" valign="top">
<p class="p31">&Eacute;rv&eacute;nyes mozg&aacute;ss&eacute;r&uuml;lt igazolv&aacute;nnyal rendelkező szem&eacute;ly</p>
</td>
</tr>
<tr>
<td class="td6" valign="top">
<p class="p31">Mozg&aacute;skorl&aacute;tozott kis&eacute;rő jegy</p>
</td>
<td class="td6" valign="top">
<p class="p31">&Eacute;rv&eacute;nyes mozg&aacute;ss&eacute;r&uuml;lt igazolv&aacute;nnyal rendelkező szem&eacute;lyt kis&eacute;rő szem&eacute;ly</p>
</td>
</tr>
<tr>
<td class="td7" valign="top">
<p class="p31">Korl&aacute;tozott kil&aacute;t&aacute;s&uacute; jegy</p>
</td>
<td class="td8" valign="top">
<p class="p31">B&aacute;rmely szem&eacute;ly aki nincs eltiltva az esem&eacute;ny l&aacute;togat&aacute;s&aacute;t&oacute;l. FIGYELEM! A Korl&aacute;tozott kil&aacute;t&aacute;s&uacute; helyekről nem l&aacute;that&oacute; a p&aacute;lya teljes terjedelme!</p>
</td>
</tr>
</tbody>
</table>
</div>
