<input 
  list="ice-cream-flavors"
  [required]="required" 
  [ngModel]="value" 
  (ngModelChange)="inputValueChangedFn($event)" 
  [placeholder]="placeholder" 
  [class]="customCssClasses + ' bg-[#f3f3f3] p-3 placeholder-[#919191] color-black w-full rounded-[5px] outline-none border border-transparent'"
/>

<datalist id="ice-cream-flavors">
  <option *ngFor="let option of options" [value]="option"></option>
</datalist>
