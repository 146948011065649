import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { ApiRequestInterface } from 'src/app/interfaces/ApiRequest/api-request-interface';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { environment } from 'src/environments/environment';
import { ModalService } from '../Modal/modal.service';
import { CartService } from '../Cart/cart.service';
import { clearCart } from 'src/app/actions/cart.actions';
import { AuthService } from '../Auth/auth.service';
import { CookieService } from '../Cookie/cookie.service';
import { logout } from 'src/app/actions/auth.actions';
import { CartError } from 'src/app/enums/cart/errors';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  
  constructor(
    private http: HttpClient,
    private _modalService: ModalService,
    private _store: Store,
    private _cookieService: CookieService,
    ) {
    
  }

  public getRequest(route: string, queryData: [] = []) {
    const r = new Promise(async (resolve, reject) =>  {
      try {
        const req$ = this.http.get<any>(environment.apiUrl + route + this.appendQueryString(queryData ?? null)).pipe()
        const a =  await lastValueFrom(req$)
        resolve(a)
      } catch (e) {
        this.handleError(e)
        reject(e);
      }
    })

    return r as Promise<any>
  }

  private _fileRequest(route: string, queryData: [] = [], type: string) {
    const r = new Promise(async (resolve, reject) =>  {
      try {
        const options = {
          responseType: 'blob' as 'json'
        }
        const req$ = this.http.get<any>(environment.apiUrl + route + this.appendQueryString(queryData ?? null), options).pipe()
        const a =  await lastValueFrom(req$)
        resolve(a)
      } catch (e) {
        this.handleError(e)
        reject(e);
      }
    })

    return r as Promise<any>
  }

  public async downloadFileRequest(route: string, queryData: [] = [], type: string, file : {filename: string, extension: string}) {
    const data = await this._fileRequest(route, queryData, type)
    const blob = new Blob([data], { type: type });
    this.downloadBlob(blob, file.filename, file.extension)
  }

  downloadBlob(data: Blob, filename: string, extension: string) {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}.${extension}`);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  public deleteRequest(route: string, queryString: [] = []) {
    const r = new Promise(async (resolve, reject) =>  {
      try {
        const req$ = this.http.delete<any>(environment.apiUrl + route + this.appendQueryString(queryString ?? null)).pipe()
        const a =  await lastValueFrom(req$)
        resolve(a)
      } catch (e) {
        this.handleError(e)
        reject(e)
      }
    })

    return r as Promise<any>
  }

  public postRequest(route: string, data: object = {}, queryString: [] = []) {
    const r = new Promise(async (resolve, reject) =>  {
      try {
        const req$ = this.http.post<any>(environment.apiUrl + route + this.appendQueryString(queryString ?? null), data).pipe()
        const a =  await lastValueFrom(req$)
        resolve(a)
      } catch (e) {
        this.handleError(e)
        reject(e);
      }
    })

    return r as Promise<any>
  }

  public putRequest(route: string, data: object = {}, queryString: [] = []) {
    const r = new Promise(async (resolve, reject) =>  {
      try {
        const req$ = this.http.put<any>(environment.apiUrl + route + this.appendQueryString(queryString ?? null), data).pipe()
        const a =  await lastValueFrom(req$)
        resolve(a)
      } catch (e) {
        this.handleError(e)
        reject(e);
      }
    })

    return r as Promise<any>
  }

  private appendQueryString(data:any = null) : string
  {
    if(!data || !data.length) return '';

    return '?' + new URLSearchParams(data).toString();
  }

  handleError(e: any) {
    /* the given cart ID is invalid */
    if(e && e.error && [CartError.CART_NOT_FOUND, CartError.CART_BELONGS_TO_ANOTHER_USER, CartError.CART_NOT_MY_CART].includes(e.error.status)) {
     this.clearCart()
    }
    /* the user is not authenticated */
    else if(e && e.error && e.error.status == CartError.NOT_AUTHENTICATED) {
      this._store.dispatch(logout())
      this._cookieService.deleteCookie('access_token')
      this._cookieService.deleteCookie('refresh_token')
      this._cookieService.deleteCookie('expires_at')
      this._modalService.showLoginModal()
    }
    /* the user is not authenticated then logout */
    else if(e && e.error && e.error.status == CartError.NOT_AUTHENTICATED_AND_LOGOUT) {
      this._store.dispatch(logout())
      this._cookieService.deleteCookie('access_token')
      this._cookieService.deleteCookie('refresh_token')
      this._cookieService.deleteCookie('expires_at')
      this._modalService.showLoginModal()
    }
  }

  public clearCart() {
    localStorage.removeItem('cartId')
    this._store.dispatch(clearCart())
  }
}
