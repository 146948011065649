import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent {
  error: string = ''
  
  public isRequesting: boolean = false
  private formSubmitAttempt: boolean = false;

  registerForm = this._formBuilder.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    password_confirmation: ['', [Validators.required]],
    terms: [false, [Validators.requiredTrue]],
  });

  constructor(
    private _modalService: ModalService,   
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
  ) {
  }

  hideModal() {
    this._modalService.hideModal()
  }

    
  setValue(property: string, value: any) {
    this.registerForm.get(property)?.setValue(value)
  }

  async onSubmit() {
    if(this.isRequesting) return ;

    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""

    if(!this.registerForm.valid) {
      this.isRequesting = false
      return ;
    }
  
    const res = await this._authService.register(
      this.firstName!.value!,
      this.lastName!.value!,
      this.email!.value!, 
      this.password!.value!, 
      this.passwordConfirmation!.value!, 
      this.terms!.value ?? false)

    this.isRequesting = false

    if(res === true) {
      this.reset()
      this.hideModal()
      return ;
    }

    this.error = res
  }

  reset() {
    this.registerForm.reset()
    this.formSubmitAttempt = false
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.registerForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.registerForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.registerForm.get(field)!.valid && this.formSubmitAttempt
  }

  get firstName() { return this.registerForm.get('first_name') }
  get lastName() { return this.registerForm.get('last_name') }
  get email() { return this.registerForm.get('email') }
  get password() { return this.registerForm.get('password') }
  get passwordConfirmation() { return this.registerForm.get('password_confirmation') }
  get terms() { return this.registerForm.get('terms') }

}
