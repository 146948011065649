import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Position } from 'src/app/interfaces/Class/Stadium/position';
import { Zoomer } from 'src/app/interfaces/Class/Stadium/zoomer';

@Component({
  selector: 'app-map-zoomer',
  templateUrl: './zoomer.component.html',
  styleUrls: ['./zoomer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomerComponent {
  @Input() zoom: Zoomer = {
    zoomInPossible: true,
    zoomOutPossible: false
  }

  @Input() pos: Position = {
    x: 0,
    y: 0,
  }

  @Input() scale: number = 1
  @Output() zoomReset: EventEmitter<any> = new EventEmitter();
  @Output() zoomIn: EventEmitter<any> = new EventEmitter();
  @Output() zoomOut: EventEmitter<any> = new EventEmitter();

  isZoomCanBeReseted() {
    return this.scale != 1 || this.pos.x != 0 || this.pos.y != 0
  }

  zoomResetFn() {
    this.zoomReset.emit(null)
  }

  zoomInFn() {
    this.zoomIn.emit(null)
  }

  zoomOutFn() {
    this.zoomOut.emit(null)
  }
 }
