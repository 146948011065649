import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'src/app/services/Cookie/cookie.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent {
  public hiddenFooter = ['/merkozes/', '/berlet/', '/test', '?only-map=1']
  public hiddenNavbar = ['?only-map=1']
  public showFooter: boolean = true
  public showNavbar: boolean = true
  public mapOnly: boolean = false
  public isPrivacyPage: boolean = false
  public cookieAccepted: boolean = false

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _cookieService: CookieService,

  ) {
    this.cookieAccepted = _cookieService.getCookie('cookie_accepted_v1') ? true : false

    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkFooterVisibility(val.url)
        this.checkNavbarVisibilty(val.url)
        this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
        this.isPrivacyPage = val.urlAfterRedirects === '/adatvedelmi-iranyelvek';
      }
    });
  }

  public checkFooterVisibility(url: string) {
    this.showFooter = true
    this.hiddenFooter.map((element) => {
      if (url.indexOf(element) == 0 || url.indexOf(element) !== -1) {
        this.showFooter = false
      }
    })
  }

  public checkNavbarVisibilty(url: string) {
    this.showNavbar = true
    this.hiddenNavbar.map((element) => {
      if (url.indexOf(element) !== -1) {
        this.showNavbar = false
      }
    })
  }

  public acceptCookie() {
    this._cookieService.setCookie({
      name: 'cookie_accepted_v1', 
      value: 1,
      expireDays: 365,
    })
    this.cookieAccepted = true
  }


}
