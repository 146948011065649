<div id="informations" class="bg-[#0e1e2b] p-5" [ngClass]="{'!mt-0' : mapOnly}" *ngIf="!errorMsg">
    <div class="container">
        <section>
            <!-- FIXTURE INFOBAR -->
            <app-infobar *ngIf="openedFixture?.id" [mapOnly]="mapOnly" [openedFixture]="openedFixture"></app-infobar>

            <!-- SEASON PASS INFOBAR -->
            <app-infobar *ngIf="openedSeasonPass?.slug"  [mapOnly]="mapOnly" [openedSeasonPass]="openedSeasonPass"></app-infobar>
        </section>
    </div>
</div>
<div id="loader-holder"  *ngIf="isLoadingInitially">
    <div class="loader"></div>
</div>

<div class="flex justify-between  bg-[#0e1e2b] sm:flex-col " [ngClass]="{'sm:bg-[var(--primary-color)]' : showStadium()}" id="stadium-holder" *ngIf="!isLoadingInitially">
    <div *ngIf="errorMsg" class="text-white font-bold h-screen flex items-center justify-center w-full" [innerHTML]="errorMsg">
    </div>
    <ng-container *ngIf="!errorMsg">
        <section id="outer-holder" class="sm:w-full relative" [ngClass]="{'!w-full !h-screen' : false && mapOnly, 'sm:!hidden' : entityLoaded() && !showStadium(), 'cashier_mode' : isCashier()}">
            <div class="w-full h-full absolute z-10 bg-[rgba(0,0,0,.7)]" *ngIf="showBlackLayer"></div>

            <!-- SEATMAP -->
            <ng-container *ngIf="showStadium() && entityLoaded()">
                <div id="map-holder">
                <!-- ZOOM COMPONENT -->
                <app-map-zoomer
                    [zoom] = "zoom"
                    [scale] = "scale"
                    [pos]="{x: matrix._4, y: matrix._5}"
                    (zoomIn)="zoomIn()"
                    (zoomOut)="zoomOut()"
                    (zoomReset)="zoomReset()"
                >
                </app-map-zoomer>
                <!-- ZOOM COMPONENT -->

                <!-- TOOLTIP -->
                <div id="tooltip" [ngStyle]="tooltipStyle" *ngIf="isDesktop">
                    <div id="tooltip-title" [innerHTML]="toolTip.title"></div>
                    <div id="tooltip-content" [innerHTML]="toolTip.content"></div>
                </div>
                <!-- TOOLTIP -->

                <!-- STADIUM COMPONENT -->
                <div id="svg-holder" [ngClass]="{'transtition ease-linear duration-200' : shouldApplyTransition}" [ngStyle]="mapStyle" >
                    <app-stadium-holder
                        [commonState]="commonState"
                        [scale]="scale"
                        [stadiumState]="stadiumState"
                        [soldSeats]="soldSeats"
                        [deletingSeats]="deletingSeats"
                        [seatDetails]="seatDetails"
                        [sectorData]="sectorData"
                        [fixtureId]="fixtureId"
                        [seasonPassId]="seasonPassId"
                        [cart]="cart"
                        [user]="auth.user"
                        [purchaseType]="purchaseType"
                        (sectorClickedEvent)="zoomToSector($event)"
                        [canInteractWithSeat]="canInteractWithSeat" 
                    ></app-stadium-holder>
                        <!--<app-test-stadium />-->
                </div>
                </div>
            </ng-container>
            <!-- SEATMAP -->

            <!-- PICTURE -->
            <ng-container *ngIf="hasPictureBackground()">
                <img class="sm:hidden" id="background-image" [src]="openedFixture.background_media">
            </ng-container>
            <!-- PICTURE -->

            <!-- VIDEO -->
            <ng-container *ngIf="hasVideoBackground()">
                <video class="absolute z-0 sm:hidden w-full h-full object-cover" preload="auto" [muted]="true" autoplay loop>
                    <source [src]="openedFixture.background_media" type="video/mp4">
                </video>
            </ng-container>
            <!-- VIDEO -->
        </section>
        <section id="tickets-bar" class="w-[30%] sm:w-full sm:h-[40vh] bg-white" *ngIf="true || !mapOnly" [ngClass]="{'stadium-tickets' : showStadium(), 'cashier_mode' : isCashier()}">
            <!-- STADIUM CART -->
            <app-stadium-cart 
                *ngIf="entityLoaded() && showStadium()"
                (editingTicketTypeEvent)="editingTicketTypeEventFn($event)" 
                (editingSeasonPassTypeEvent)="editingSeasonPassTypeEventFn($event)"
                [stadiumState]="stadiumState" 
                [cart]="cart" 
                [currentFixtureId]="fixtureId"
                [currentSeasonPassSlug]="seasonPassId"
            />
            <!-- STADIUM CART -->

            <!-- SECTORS LIST -->
            <app-sectors-list
                *ngIf="entityLoaded() && !showStadium()"
                [cart]="cart" 
                [currentFixtureSlug]="fixtureId"
                [stadiumState]="stadiumState" 
            />
            <!-- SECTORS LIST -->
        </section>
    </ng-container>
</div>