<section class="flex">
    <div class="grow tablet:hidden bg-primary">
      <img *ngIf="game.logo" class="object-cover w-full h-full" (src)="game.logo"  />
    </div>
    <app-game-quiz
  
      [game]="game"
      (close)="toHomepage()"
      (finished)="toCompleted()"
  
    />
  </section>
