<div id="zoom-holder">
    <div id="buttons-holder">
        <button 
            id="reset-button" 
            [ngClass]="{disabled : !isZoomCanBeReseted()}"
            (click)="zoomResetFn()">
            <img src="/assets/icons/resetbutton.svg">
        </button>
        <div id="zoom-buttons">
            <button (click)="zoomInFn()" [ngClass]="{disabled : !zoom.zoomInPossible}"><img src="/assets/icons/plus.svg"></button>
            <button (click)="zoomOutFn()" [ngClass]="{disabled : !zoom.zoomOutPossible}"><img src="/assets/icons/minus.svg"></button>
        </div>
    </div>
</div>