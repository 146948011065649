import { createReducer, on } from "@ngrx/store";
import { clearETicket, clearUpcomingMyTickets, setETicket, setPastMyTickets, setUpcomingMyTickets, updateUpcomingMyTicket } from "../actions/mytickets.actions";
import { State } from "../interfaces/State/MyTickets/state";
import { SingleTicket } from "../interfaces/State/MyTickets/singleTicket";

export const initialState = {
    upcoming: undefined,
    past: undefined,
    eTicket: {} as SingleTicket,
} as State

export const MyTicketReducer = createReducer(
    initialState,
    on(setUpcomingMyTickets, (state, {tickets}) => ({...state, upcoming : tickets})),
    on(clearUpcomingMyTickets, (state) => ({...state, upcoming : undefined})),
    on(setPastMyTickets, (state, {tickets}) => ({...state, past : tickets})),
    on(setETicket, (state, {ticket}) => ({...state, eTicket : ticket})),
    on(clearETicket, (state,) => ({...state, eTicket : {} as SingleTicket })),
    on(updateUpcomingMyTicket, (state, { ticket, fixtureSlug }) => {
        
        const updatedUpcoming = state.upcoming?.map(fixtureGroup => {
    
          if (fixtureGroup.fixture?.slug === fixtureSlug) {
    
            const updatedTickets = fixtureGroup.tickets?.map(t => {
    
              if (t.uuid === ticket.uuid) {
                return { ...t, ...ticket };
              }
    
              return t;
            }) ?? [];
    
            return {
              ...fixtureGroup,
              tickets: updatedTickets,
            };
          }
    
          return fixtureGroup;
        }) ?? [];
        
        return {
          ...state,
          upcoming: updatedUpcoming,
        };
    }),
);