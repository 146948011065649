import { Component, Input } from '@angular/core';
import { Auth } from 'src/app/interfaces/State/Auth/auth';

@Component({
  selector: 'app-login-register-content',
  templateUrl: './login-register-content.component.html',
  styleUrls: ['./login-register-content.component.scss']
})
export class LoginRegisterContentComponent {
  type: string = 'login'
  @Input() auth!: Auth

  ngOnInit() {
    this.type = this.isUserLoggedIn() ? 'forgot-password' : 'login'
  }

  isUserLoggedIn() {
    return this.auth && this.auth.isUserLoggedIn
  }
}
