import { Component, OnInit } from '@angular/core';
import { State } from 'src/app/interfaces/State/Fixture/state';
import { FixtureService } from 'src/app/services/Fixture/fixture.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HomepageService } from 'src/app/services/Homepage/homepage.service';
import { Homepage } from 'src/app/interfaces/State/Homepage/homepage';
import { SeasonPassService } from 'src/app/services/SeasonPass/season-pass.service';
import { State as SeasonPassState} from 'src/app/interfaces/State/SeasonPass/state';

@Component({
  selector: 'app-homescreen',
  templateUrl: './homescreen.component.html',
  styleUrls: ['./homescreen.component.scss']
})
export class HomescreenComponent implements OnInit{
  public fixtures: State = {
    upcomingFixtures : undefined,
  }

  public seasonPasses: SeasonPassState = {
    seasonPasses: undefined,
  }

  public homepage: Homepage = {} as Homepage
  public isLoadingInitially: boolean = true
  private _subscription?: Subscription

  constructor(
    private _fixtureService: FixtureService,
    private _store: Store,
    private _homepageService: HomepageService,
    private _seasonPassService: SeasonPassService,
  ) {
  }

  async ngOnInit() {
    await this._homepageService.getHomepage()

    this._subscription = this._store.subscribe((state: any) => {
      this.fixtures = state.fixtures
      this.homepage = state.homepage
      this.seasonPasses = state.seasonPasses

      if(this.homepage) {
        this.isLoadingInitially = false
      }
    })

    await this._fixtureService.getFixtures()
    await this._seasonPassService.getSeasonPasses()
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  get bg() {

    if(this.homepage?.homepage_background && window.outerWidth > 600) {
      return this.homepage?.homepage_background 
    } else if(this.homepage?.mobile_homepage_background ) {
      return this.homepage?.mobile_homepage_background 
    }
    return '';
  }

  showMobileBg() {
    return window.outerWidth < 600
  }

  showDesktopVideo() {
    return !this.showMobileBg() && this.homepage.homepage_background_video
  }
}
