import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfobarComponent } from './infobar.component';
import { BackModule } from 'src/app/components/assets/icons/back/back.module';
import { RouterModule } from '@angular/router';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';

@NgModule({
  declarations: [InfobarComponent],
  imports: [
    CommonModule,
    RouterModule,
    LinkButtonModule,
    BackModule,
  ],
  exports: [
    InfobarComponent,
  ]
})
export class InfobarModule { }
