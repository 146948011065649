import { Component } from '@angular/core';

@Component({
  selector: 'app-back-icon',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {

}
