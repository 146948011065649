import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { LoginRegisterModalModule } from '../login-register-modal/login-register-modal.module';
import { InvoiceDetailsModule } from '../cart/invoice-details/invoice-details.module';
import { PersonalDetailsModule } from '../cart/personal-details/personal-details.module';
import { EditTicketTypeModule } from '../edit-ticket-type/edit-ticket-type.module';
import { StandingSectorTicketSelectorModule } from '../standing-sector-ticket-selector/standing-sector-ticket-selector.module';
import { SpecialAccessComponent } from '../special-access/special-access.component';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    LoginRegisterModalModule,
    InvoiceDetailsModule,
    PersonalDetailsModule,
    EditTicketTypeModule,
    StandingSectorTicketSelectorModule,
    SpecialAccessComponent,
  ],
  exports: [MainComponent],
})
export class ModalsModule { }
