import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { Store } from '@ngrx/store';
import { GameView } from 'src/app/interfaces/State/Game/GameView';
import { setGame } from 'src/app/actions/game.actions';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  public async getGameBySlug(slug: string) {
    const res = await this._apiRequestService.getRequest('games/' + slug);
    
    this._store.dispatch(setGame({ game: res.data }));

  }
}
