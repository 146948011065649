import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'src/app/services/Cookie/cookie.service';

@Injectable()
export class SpecialAccessCodeInterceptor implements HttpInterceptor {

  constructor(
    private _cookieService: CookieService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const specialAccessCode = this._cookieService.getCookie('special-access-code')
   
    /* if there is cart ID */
    if(specialAccessCode) {
      request = request.clone({
        setHeaders: {
          'special-access-code': specialAccessCode,
        }
      })
    }
    
    return next.handle(request);
  }
}
