import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameQuizComponent } from './game-quiz.component';
import { XModule } from '../../assets/icons/x/x.module';
import { TabsHolderModule } from '../../common/tabs-holder/tabs-holder.module';



@NgModule({
  declarations: [GameQuizComponent],
  imports: [
    CommonModule,
    XModule,
    TabsHolderModule,
  ],
  exports: [
    GameQuizComponent,
  ],
})
export class GameQuizModule { }
