import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-skeleton-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input() row?: number
  @Input() aspectRatio?: string
  @Input() isFullHeight: boolean = false
  @Input() classList?: string

  get height() {
    if(!this.row) return ''

    return 'height:' + (this.row * 32) + 'px;'
  }

  get ratio() {
    if(!this.aspectRatio) return ''
    return 'aspect-ratio:' + this.aspectRatio + ';'
  }
}
