import { createReducer, on } from "@ngrx/store";
import { login, logout, setUser } from "../actions/auth.actions";
import { Auth } from "../interfaces/State/Auth/auth";
import { User } from "../interfaces/State/User/user";

export const initialState = {
    isUserLoggedIn: false,
    user : { } as User
} as Auth

export const AuthReducer = createReducer(
    initialState,
    on(login, (state) => ({...state, isUserLoggedIn: true})),
    on(logout, (state) => ({...state, isUserLoggedIn: false})),
    on(setUser, (state, {user}) => ({...state, user : {...user}}))
);