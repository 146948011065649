<div class="container p-10 bg-white rounded-[20px] !my-[30px] sm:p-5" [ngClass]="{'!my-0 !max-w-full !rounded-none !-mt-[10px] overflow-x-hidden' : mapOnly}" >
<p class="p1"><strong>Adatkezelési Szabályzat</strong></p>
<p class="p2">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p4">&nbsp;</p>
<p class="p5"><strong>I. Adatkezelő (Szolgáltató) adatai</strong></p>
<p class="p6">&nbsp;</p>
<p class="p7">Az Adatkezelési Szabályzat célja, hogy a csakjegyek.hu weboldal és a Szolgáltató által publikált Applikációk szolgáltatásainak használatával összefüggésben a Felhasználók teljeskörű tájékoztatást kapjanak személyes adataik kezeléséről.</p>
<p class="p8">&nbsp;</p>
<p class="p9"><span class="s1"><strong>Név: </strong></span>Csakjegyek Kft.</p>
<p class="p9"><span class="s1"><strong>Székhely: </strong></span>1163 Budapest, Cziráki utca 24-32. A1. ép. 2. em. 110. ajtó</p>
<p class="p9"><span class="s1"><strong>Adószám: </strong></span>25935576-2-42</p>
<p class="p10"><strong>Cégjegyzékszám: </strong><span class="s2">01 09 297248</span></p>
<p class="p11"><span class="s3"><strong>E-mail cím: </strong></span><a href="mailto:info@csakjegyek.hu"><span class="s3 s4"><strong>info@csakjegyek.hu</strong></span></a></p>
<p class="p9"><span class="s1"><strong>Weboldal: </strong></span>csakjegyek.hu</p>
<p class="p12">&nbsp;</p>
<p class="p13"><strong>II. Adatkezelő kötelezettségei</strong></p>
<p class="p14">&nbsp;</p>
<p class="p15">Az Adatkezelő minden esetben biztosítja az általa kezelt személyes adatok tekintetében az adatkezelés jogszerűségét és célhoz kötöttségét. Adatkezelő továbbá gondoskodik róla, hogy jelen szabályzat folyamatosan elérhető legyen a <a target="_blank" rel="noopener noreferrer" href="http://csakjegyek.hu"><span class="s4">csakjegyek.hu</span></a> weboldalon és az Adatkezelő által üzemeltetett applikációkban.</p>
<p class="p14">&nbsp;</p>
<p class="p15">Az Adatkezelő fenntartja a jogot jelen Szabályzat módosítására, az ezzel kapcsolatos értesítésről a módosított Szabályzat honlapon (és applikációkban) történő közzétételével gondoskodik. Amennyiben a Felhasználó nem fogadja el a módosított Adatkezelési Szabályzatot, úgy számára Adatkezelő korlátozhatja vagy megtagadhatja a szolgáltatás elérését és további használatát.</p>
<p class="p16">&nbsp;</p>
<p class="p13"><strong>III. Az adatkezelés célja</strong></p>
<p class="p17"><br>&nbsp;</p>
<p class="p18">Az adatkezelés célja jegyértékesítési, bérletértékesítés, valamint szurkolói élmény és szurkolói tájékoztatás biztosítása a Felhasználók részére, továbbá a Szolgáltató üzleti érdekeinek védelme, valamint jogszabályi megfelelés biztosítása.</p>
<p class="p18">Jegyvásárlás vagy bérletvásárlás esetén a megrendelés létrehozásához, teljesítéséhez, az ahhoz kapcsolódó fizetés megvalósításához, illetve a számviteli kötelezettség teljesítéséhez és a felhasználó élmény javításának céljából Szolgáltató adatokat kezel. A jegyvásárlással kapcsolatban megadott adatokat a Csakjegyek Kft. kezeli, aki rendezvényszervezők megbízásából bizományosi értékesítést végez. A rendezvényszervező előírhatja a névre szóló jegyértékesítést mely esetben nem Önkéntes hozzájárulás az megadott személyes adatok jogalapja, hanem A sportról szóló 2004. évi I. törvény (az „Stv.”) 72/B. §.</p>
<p class="p18">Ezenkívül lojalitási program, elektronikus hírlevél, játékok, promóciós és kapcsolattartás, valamint a Szolgáltató üzleti érdekeinek védelme és jogszabályi megfelelés biztosítása céljából kezel a GDPR 6. cikk (1) a pontja alapján személyes adatokat kezelhet.</p>
<p class="p18"><br><br>&nbsp;</p>
<p class="p19">&nbsp;</p>
<p class="p13"><strong>IV. Az adatkezelés jogalapja</strong></p>
<p class="p20">&nbsp;</p>
<p class="p21">Az adatkezelés jogalapja a GDPR 6. cikk (1), mely szerint a személyes adatok kezelése kizárólag akkor és annyiban jogszerű, amennyiben legalább az alábbiak egyike teljesül:</p>
<p class="p22">a) az érintett hozzájárulását adta személyes adatainak egy vagy több konkrét célból történő kezeléséhez&nbsp;</p>
<p class="p22">b) az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges</p>
<p class="p22">c) az adatkezelés az adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez szükséges</p>
<p class="p22">d) az adatkezelés az érintett vagy egy másik természetes személy létfontosságú érdekeinek védelme miatt szükséges</p>
<p class="p22">e) az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat végrehajtásához szükséges</p>
<p class="p22">f) az adatkezelés az adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges, kivéve, ha ezen érdekekkel szemben elsőbbséget élveznek az érintett olyan érdekei vagy alapvető jogai és szabadságai, amelyek személyes adatok védelmét teszik szükségessé, különösen, ha az érintett gyermek.</p>
<p class="p23">&nbsp;</p>
<p class="p21"><span class="s1"><strong>V. Kezelt adatok köre, jogalapja és időtartama</strong></span><br><br>A <a target="_blank" rel="noopener noreferrer" href="http://csakjegyek.hu"><span class="s4">csakjegyek.hu</span></a> oldal felhasználói és a Csakjegyek Kft. által Apple App Storeban és Google Play Storeban publikált applikációinak felhasználói hozzájárulnak ahhoz, hogy a Szolgáltató azonosítható információkat rendszer szinten gyűjtsön és azt jelen Adatvédelmi Szabályzat szerint felhasználja.</p>
<p class="p21">Felhasználó tudomásul veszi és hozzájárul ahhoz, hogy a Szolgáltató által üzemeltetett weboldalak és applikációk használata során az alábbi nem személyes adatok automatikus gyűjtésre és tárolásra kerülnek. Ezen információk a Felhasználó beazonosítására nem alkalmasak, csupán a rendszer szintű folyamatok optimalizálása, valamint a felhasználói élmény növelése érdekében és fogyasztói trendeket analizálását szolgálják.</p>
<figure class="table">
    <table class="t1" cellspacing="0" cellpadding="0">
        <tbody>
            <tr>
                <td class="td1" valign="middle">
                    <p class="p24">Kezelt, nem személyes adatok köre</p>
                </td>
                <td class="td2" valign="middle">
                    <p class="p24">Adatkezelés célja és jogalapja</p>
                </td>
                <td class="td3" valign="middle">
                    <p class="p24">Időtartam</p>
                </td>
            </tr>
            <tr>
                <td class="td4" valign="middle">
                    <p class="p24">IP cím</p>
                </td>
                <td class="td5" valign="middle">
                    <p class="p24">Az informatikai rendszerek üzemeltetésének biztosítása céljából. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td6" valign="middle">
                    <p class="p24">A keletkezéstől számított 5 évig.</p>
                </td>
            </tr>
            <tr>
                <td class="td7" valign="middle">
                    <p class="p24">Böngészők által biztosított technikai adatok</p>
                </td>
                <td class="td8" valign="middle">
                    <p class="p24">Az informatikai rendszerek üzemeltetésének biztosítása céljából. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td9" valign="middle">
                    <p class="p24">A keletkezéstől számított 5 évig.</p>
                </td>
            </tr>
            <tr>
                <td class="td10" valign="middle">
                    <p class="p24">Operációs rendszer és készülék típusa</p>
                </td>
                <td class="td11" valign="middle">
                    <p class="p24">Az informatikai rendszerek üzemeltetésének biztosítása céljából. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td12" valign="middle">
                    <p class="p24">A keletkezéstől számított 5 évig.</p>
                </td>
            </tr>
            <tr>
                <td class="td13" valign="middle">
                    <p class="p24">Megtekintett oldalak és használt funkciók</p>
                </td>
                <td class="td14" valign="middle">
                    <p class="p24">Az informatikai rendszerek üzemeltetésének biztosítása céljából. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td15" valign="middle">
                    <p class="p24">A keletkezéstől számított 5 évig.</p>
                </td>
            </tr>
            <tr>
                <td class="td10" valign="middle">
                    <p class="p24">Dátum/idő bélyeg és a belépési folyamat adatai</p>
                </td>
                <td class="td11" valign="middle">
                    <p class="p24">Az informatikai rendszerek üzemeltetésének biztosítása céljából. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td12" valign="middle">
                    <p class="p24">A keletkezéstől számított 5 évig.</p>
                </td>
            </tr>
        </tbody>
    </table>
</figure>
<p class="p23">&nbsp;</p>
<p class="p25"><br>&nbsp;</p>
<p class="p21">Felhasználó tudomásul veszi és hozzájárul ahhoz, hogy a Szolgáltató által üzemeltetett weboldalak és applikációk bizonyos funkcióinál az alábbi személyes adatok megadására is sor kerülhet, melyeket Szolgáltató az alábbi táblázat szerint kezel. Az önkéntes hozzájáruláson alapuló adatkezelések esetében az érintettek e hozzájárulásukat az adatkezelés bármely szakaszában visszavonhatják.</p>
<p class="p21">Amennyiben adatközlő nem saját személyes adataikat adják meg, az adatközlő kötelessége az érintett hozzájárulásának beszerzése.&nbsp;</p>
<p class="p25"><br>&nbsp;</p>
<figure class="table">
    <table class="t1" cellspacing="0" cellpadding="0">
        <tbody>
            <tr>
                <td class="td16" valign="middle">
                    <p class="p24">Kezelt személyes adatok</p>
                </td>
                <td class="td16" valign="middle">
                    <p class="p24">Adatkezelés célja és jogalapja</p>
                </td>
                <td class="td17" valign="middle">
                    <p class="p24">Időtartama</p>
                </td>
            </tr>
            <tr>
                <td class="td18" valign="middle">
                    <p class="p24">Regisztráció során megadott e-mail cím, keresztnév, vezetéknév, jelszó</p>
                </td>
                <td class="td18" valign="middle">
                    <p class="p24">A <a target="_blank" rel="noopener noreferrer" href="http://csakjegyek.hu"><span class="s4">csakjegyek.hu</span></a> oldalon, illetve az applikációkban történő felhasználói regisztráció vásárlás és regisztrációhoz kötött funkciók használata céljából. Érintett önkéntes hozzájárulása a GDPR. 6 cikk (1) bekezdés a) pontja alapján.</p>
                </td>
                <td class="td19" valign="middle">
                    <p class="p24">A felhasználói fiók törléséig (hozzájárulás visszavonása).</p>
                </td>
            </tr>
            <tr>
                <td class="td20" valign="middle">
                    <p class="p24">Számlázási adatok (Név, Cégnév, Ország, Irányítószám, Város, Cím, Adószám)</p>
                </td>
                <td class="td20" valign="middle">
                    <p class="p24">A vásárlás/megrendelés befogadása és feldolgozása céljából. Az ÁSZF teljesítéséhez szükséges a GDPR. 6 cikk (1) bekezdés b) pontja alapján.</p>
                </td>
                <td class="td21" valign="middle">
                    <p class="p24">Általános elévülés időtartamáig (5 év).</p>
                </td>
            </tr>
            <tr>
                <td class="td22" valign="middle">
                    <p class="p24">Nem kötelezően névre szóló jegyértékesítés&nbsp; és bérletértékesítés esetén megadott adatok ((Név, Születési hely, Születési idő)</p>
                </td>
                <td class="td22" valign="middle">
                    <p class="p24">Szolgáltatások fejlesztése érdekében. Az Érintett önkéntes hozzájárulása a GDPR. 6 cikk (1) bekezdés a) pontja alapján.</p>
                </td>
                <td class="td23" valign="middle">
                    <p class="p24">A felhasználói fiók törléséig (hozzájárulás visszavonása).</p>
                </td>
            </tr>
            <tr>
                <td class="td24" valign="middle">
                    <p class="p24">Kötelezően névre szóló jegyértékesítés&nbsp; és bérletértékesítés esetén megadott adatok ((Név, Születési hely, Születési idő, Anyja neve)</p>
                </td>
                <td class="td24" valign="middle">
                    <p class="p24">Jogszabályi kötelezettség miatt. Az Stv. által előírva.</p>
                </td>
                <td class="td25" valign="middle">
                    <p class="p24">A sporttörvény által előírt ideig. Jegy esetén az eseményt követő 72 óráig, Bérlet esetén az utolsó bérlet által tartalmazott eseményt követő 72 óráig.</p>
                </td>
            </tr>
            <tr>
                <td class="td26" valign="middle">
                    <p class="p24">A sportrendészeti nyilvántartásból lekért adatok, családi és utónév, születési hely és idő, anyja születési és családi és utóneve..</p>
                </td>
                <td class="td26" valign="middle">
                    <p class="p24">Szolgáltató jegy- vagy bérletvásárlásná ellenőrzi a rendőrség által vezetett sportrendészeti nyilvántartást. A Szolgáltató jogszabályban elrendelt kötelezettségek teljesít. Az adatkezelés jogalapja jogszabály rendelkezése (Stv. 76/A. §).</p>
                </td>
                <td class="td27" valign="middle">
                    <p class="p24">Adatrögzítés nem történik, az adatokat a Szolgáltató csak a lekérdezés során kezeli.</p>
                </td>
            </tr>
            <tr>
                <td class="td20" valign="middle">
                    <p class="p24">Felhasználó adatai: megrendelés, kosár, érdeklődési kör, elégedettség, kupon felhasználás, játékokban és felmérésekben való részvétel.</p>
                </td>
                <td class="td20" valign="middle">
                    <p class="p24">Profilozás. Az Érintett önkéntes hozzájárulása a GDPR. 6 cikk (1) bekezdés a) pontja alapján.</p>
                </td>
                <td class="td21" valign="middle">
                    <p class="p24">Az adatkezelés elleni tiltakozásig, egyebekben a felhasználói fiók törléséig.</p>
                </td>
            </tr>
            <tr>
                <td class="td28" valign="middle">
                    <p class="p24">Hírlevélre feliratkozó adatai: név, e-mail cím.</p>
                </td>
                <td class="td28" valign="middle">
                    <p class="p24">Marketing célú megkeresésekhez történő beleegyező nyilatkozat esetén hírlevél, push üzenet küldése. Az Érintett önkéntes hozzájárulása a GDPR. 6 cikk (1) bekezdés a) pontja alapján.</p>
                </td>
                <td class="td29" valign="middle">
                    <p class="p24">A hozzájárulás visszavonásáig.</p>
                </td>
            </tr>
            <tr>
                <td class="td30" valign="middle">
                    <p class="p24">Ügyfélszolgálati bejelentés során megadott adatok (Név, telefonszám, e-mail cím, panaszlevél teljes tartalma, így az abban megadott személyes és nem személyes adatok is.</p>
                </td>
                <td class="td30" valign="middle">
                    <p class="p24">Ügyfélszolgálati ügy intézése a célja. A GDPR 6.cikk (1) bekezdés f) pontja szerinti jogos érdek.</p>
                </td>
                <td class="td31" valign="middle">
                    <p class="p24">Általános elévülés időtartamáig (5 év).</p>
                </td>
            </tr>
        </tbody>
    </table>
</figure>
<p class="p23">&nbsp;</p>
<p class="p23">&nbsp;</p>
<p class="p13"><strong>VI. Adatok továbbítása és adatfeldolgozók megnevezése</strong></p>
<p class="p26"><br>&nbsp;</p>
<p class="p21">A <a target="_blank" rel="noopener noreferrer" href="http://csakjegyek.hu"><span class="s4">csakjegyek.hu</span></a>, illetve a Szolgáltató által üzemeltetett applikációk használata esetén a Felhasználó hozzájárul ahhoz, hogy a Szolgáltató az adatokat továbbíthassa az alábbi partnereknek. Az adattovábbítás jogalapja: a szerződés teljesítése, GDPR 6. cikk (1) bek. b) pont.</p>
<p class="p21">A bankkártyás fizetés biztonságos megvalósításához Szolgáltató harmadik fél szolgáltatását veszi igénybe, és ennek keretében továbbítja számára a következő adatokat: fizetendő összeg, tranzakció pénzneme, tranzakció azonosítója, felhasználó e-mail címe, megvásárolt termék megnevezése. A harmadik fél, mint Adatfeldolgozó adatai: SimplePay, OTP Bank Nyrt. (cégjegyzékszám: 01-10-041585, székhely: 1051 Budapest, Nádor utca 16) és OTP Mobil Kft. (cégjegyzékszám: 01-09-174466, székhely: 1138 Budapest, Váci út 135-139).</p>
<p class="p21">A számla és nyugta kiállításhoz Szolgáltató harmadik fél szolgáltatását veszi igénybe, és ennek keretében továbbítja számára a következő adatokat: Számlázási cégnév, számlázási név, számlázási ország, számlázási város, számlázási&nbsp; irányítószám, számlázási cím, adószám, tétel megnevezése, tétel ára, tétel adókulcs, vásárlás időpontja, a vásárláshoz tartozó fizetés azonosítója. A harmadik fél, mint Adatfeldolgozó adatai: Számlázz.hu, KBOSS.hu Kft. (adószám: 13421739-2-13, cégjegyzékszám: 13-09-101824, székhely: 2000 Szentendre, Táltos u. 22/b).</p>
<p class="p21">A Felhasználóknak szóló rendszer e-mailek (system mail), valamint hírlevél kiküldését az adatkezelővel kötött szerződés alapján, a Mailgun Technologies (székhely: 112 E. Pecan Street #1135, San Antonio, Texas, 78205, USA), mint adatfeldolgozó végzi.</p>
<p class="p21">A Felhasználóknak szóló applikációban engedélyezhető rendszer valamint marketingcélú push értesítések kiküldéséhez és e-mail ügyfélszolgálat biztosításához Szolgáltató harmadik fél szolgáltatását veszi igénybe. A harmadik fél, mint Adatfeldolgozó adatai: Google Ireland Ltd. (székhely: Google Building Gordon House, Barrow St, Dublin 4, Írország)<br><br>Szolgáltató tárhely szolgáltatója: Websupport Magyarország Kft. (székhely: 1119 Budapest, Fehérvári út 97-99.)</p>
<p class="p21">Szolgáltató szerver szolgáltatója: Amazon Web Services EMEA SARL À RESPONSABILITÉ LIMITÉE (székhely: 38 Avenue John F. Kennedy, L-1855 Luxemburg)</p>
<p class="p21">Szolgáltató a Magyar Labdarúgó Szövetség (továbbiakban: MLSZ) által kiírt versenysorozat keretében kiírt rendezvényre Belépőjegyet vagy Bérletet váltó nézők Belépőjegy és Bérlet adatait köteles továbbítani az MLSZ felé.</p>
<p class="p21">Szolgáltató teljes hozzáférést biztosít rendezvény szervezőjének azon Felhasználók rendezvénnyel kapcsolatos adataihoz, amelyek Belépőjegyet vagy Bérletet váltottak rendezvény szervező által szervezett eseményre.</p>
<p class="p5"><strong>VII. Cookie-k (sütik)</strong></p>
<p class="p23">&nbsp;</p>
<p class="p21">A <a target="_blank" rel="noopener noreferrer" href="http://csakjegyek.hu"><span class="s4">csakjegyek.hu</span></a> weboldal és a Szolgáltató által üzemeltetett applikációk sütiket használnak, amelynek célja, hogy minél teljesebb szolgáltatást nyújthasson a Felhasználóknak. A sütik biztosítják a weboldal és applikációk megfelelő működését, megkönnyítik annak használatát, és a Felhasználók azonosítása nélkül gyűjtenek információt a használatáról.</p>
<p class="p21">A cookie (magyarul „süti”) egy olyan kisméretű adatcsomag, amit az internetes szolgáltatások a böngészőben tárolnak el. A hatékony és modern felhasználói élményt nyújtó online szolgáltatás működéséhez elengedhetetlen technológia.</p>
<p class="p21">Amennyiben a Felhasználó nem szeretné, hogy ilyen azonosító jel kerüljön a számítógépére, módja van a böngészőjét úgy beállítani, hogy az ne engedje meg az egyedi azonosító jel elhelyezését, továbbá módja van bármikor az engedélyét visszavonni, az egyedi azonosító jelet törölni, ebben az esetben azonban lehetséges, hogy a szolgáltatásokat nem vagy nem olyan formában éri majd el az Érintett, mintha engedélyezte volna az azonosítók elhelyezését.</p>
<p class="p21">Weboldalunk és applikációnk kizárólag az alapműködéshez szükséges cookiekat használ, melyek a következők:</p>
<p class="p21">A weboldal megfelelő működésének biztosítása céljából rendszerünk saját cookie-kat használ a GDPR 6.cikk (1) bekezdés a) pontja alapján. A következő cookie-k tartoznak ide: XSRF-TOKEN (tárolási idő: 2 év); access_token (tárolási idő: 30 perc);&nbsp; refresh_token (tárolási idő: 6 hónap); _ga (tárolási idő: 2 év)</p>
<p class="p21">A weboldal megfelelő működésének biztosítása, fejlesztése céljából Google Analytics pixelt használunk a GDPR 6.cikk (1) bekezdés a) pontja alapján. A következő cookie-k tartoznak ide: ga_ (tárolási idő: 2 év); test_cookie (tárolási idő: 15 perc);&nbsp; _gid (tárolási idő: 1 nap); _ga (tárolási idő: 2 év)</p>
<p class="p23">&nbsp;</p>
<p class="p13"><strong>VIII. Adatokhoz való hozzáférés és azok módosítása, törlése</strong></p>
<p class="p26"><br>&nbsp;</p>
<p class="p21">A felhasználók az <a href="mailto:info@csakjegyek.hu"><span class="s4">info@csakjegyek.hu</span></a> e-mail címre küldött megkereséssel bármikor felvilágosítást kérhetnek személyes adatainak kezeléséről, visszavonhatják hozzájárulásukat, kérhetik adatainak törlését vagy módosítását. A Szolgáltató köteles a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 30 napon belül, közérthető formában, az érintett erre irányuló kérelmére e-mail formájában megadni a tájékoztatást.</p>
<p class="p23">&nbsp;</p>
<p class="p5"><strong>IX. Jogorvoslati lehetőség</strong></p>
<p class="p27"><br>Felhasználói panasz esetén a felhasználó jogosultak <span class="s5">az </span><a href="mailto:info@csakjegyek.hu"><span class="s5 s4">info@csakjegyek.hu</span></a> felvenni a kapcsolatot a Szolgáltatóval. A Szolgáltató vállalja, hogy a Felhasználói panaszt haladéktalanul, de legkésőbb 30 naptári napon belül kivizsgálja és a Felhasználó részére e-mailben visszajelez.</p>
<p class="p28">&nbsp;</p>
<p class="p27">Jogérvényesítési lehetőségeit az Adatvédelmi törvény, valamint a Ptk. alapján a bíróság előtt gyakorolhatja, továbbá a Budapesti Békéltető Testülethez (<a target="_blank" rel="noopener noreferrer" href="https://bekeltet.bkik.hu/"><span class="s4">https://bekeltet.bkik.hu/</span></a>) vagy a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (naih.hu; <a href="mailto:ugyfelszolgalat@naih.hu"><span class="s4">ugyfelszolgalat@naih.hu</span></a>; 1125 Budapest Szilágyi Erzsébet fasor 22/c; +36 (1) 391-1400; 1530 Budapest, Pf.: 5) is fordulhat.<br>&nbsp;</p>
</div>
