import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  // Maximum number of retry attempts
  private maxRetries = 3;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(errors => 
        errors.pipe(
          concatMap((error, count) => {
            if (count < this.maxRetries && error instanceof HttpErrorResponse && error.status === 0) {
              // Retry the request
              return of(error);
            }
            // If the error is not retriable or max retries reached, throw the error
            return throwError(error);
          }),
          delay(300) // Delay before retrying (e.g., 1 second)
        )
      ),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
