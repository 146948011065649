import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ticket-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent {
  @Input() url?: string
  public errorMsg: string = ""

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  get pdfUrl() {
    if(!this.url) return null

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
