import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent {
  @Input() placeholder: string = ""
  @Input() value: string = ""
  @Input() currentValue: string = ""
  @Input() label: string = ""
  @Input() customCssClasses: string = ""
  @Input() identifier!: string
  @Input() labelCssClasses: string = ""
  @Input() type: string = "checkbox"
  
  @Output() inputValueChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  
  public inputValueChangedFn(event: any)
  {
    this.inputValueChangedEvent.emit(event);
  }
}
