import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameQuestion } from 'src/app/interfaces/State/Game/GameQuestion';

@Component({
  selector: 'app-tabs-holder',
  templateUrl: './tabs-holder.component.html',
  styleUrls: ['./tabs-holder.component.scss']
})
export class TabsHolderComponent {

  @Output() answerSelected = new EventEmitter<number>();
  @Output() ended = new EventEmitter<void>();

  public activeTab: number = 1;
  @Input() items: GameQuestion[] = [] as GameQuestion[];

  constructor(
  ) {}

  async handleAnswerSelected(ev: MouseEvent, answerId: number, questionId: number)
  {
    (ev.target as HTMLElement|null)?.focus();

    if(this.activeTab < this.items.length){
      setTimeout(() => {
        this.next();
      }, 50);
    }
    else {
      this.next();
    }
  
    this.answerSelected.emit(answerId);
    if(this.isEnded())
    {
      this.ended.emit();
    }
  }

  isEnded(): boolean
  {
    return this.activeTab > this.items.length;
  }

  next()
  {
    this.activeTab++;
  }
  prev()
  {
    this.activeTab--;
  }
}
