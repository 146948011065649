import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-rounded-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss']
})
export class RoundedButtonComponent {
  @Input() disabled: boolean = false
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter();

  buttonClickedFn() {
    this.buttonClicked.emit();
  }
}
