import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../ApiRequest/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public async getAllCountries() {
    return await this._apiRequestService.getRequest('settings/countries')
  }
}
