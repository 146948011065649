import { createReducer, on } from "@ngrx/store";
import { setStadiumSeats } from "../actions/stadium.actions";
import { StadiumWithSectorsAndSeats } from "../interfaces/State/Stadium/stadium-with-sectors-and-seats";

export const initialState = {
    data: { } as StadiumWithSectorsAndSeats,
}

export const StadiumReducer = createReducer(
    initialState,
    on(setStadiumSeats, (state, {stadium}) => ({...state, data : {...stadium}}))
);