import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setSoldSeats } from 'src/app/actions/seats.actions';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { setSectorData } from 'src/app/actions/sector.actions';
import { SectorData } from 'src/app/interfaces/State/Sector/sector-data';
import { setESeasonPass, setETicket } from 'src/app/actions/mytickets.actions';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async getSoldTicketsForFixture(fixtureId: string) {
    if(!fixtureId || !fixtureId.length) return ;

    try {
      const res = await this._apiRequestService.getRequest('fixtures/' + fixtureId + '/sold-seats')
      this._store.dispatch(setSoldSeats({ soldSeats: res.data.sold_seats }))
    } catch (e: any) {
      ;
    }
  }

  async getSectorDataForFixture(fixtureId: string) {
    if(!fixtureId || !fixtureId.length) return ;
    const res = await this._apiRequestService.getRequest('fixtures/' + fixtureId + '/sector-data')
    this._store.dispatch(setSectorData({ sectorData: res.data.sector_data }))
  }

  async getSectorDataForSeasonPass(seasonPassId: string) {
    if(!seasonPassId || !seasonPassId.length) return ;
    const res = await this._apiRequestService.getRequest('season-passes/' + seasonPassId + '/sector-data')
    this._store.dispatch(setSectorData({ sectorData: res.data.sector_data }))
  }

  async getSoldTicketsForSeasonPass(seasonPassId: string) {
    if(!seasonPassId || !seasonPassId.length) return ;
    try {
      const res = await this._apiRequestService.getRequest('season-passes/' + seasonPassId + '/sold-seats')
      this._store.dispatch(setSoldSeats({ soldSeats: res.data.sold_seats }))
    } catch(e: any)
    {
      ;
    }
    
  }

  async getTicketInformation(fixtureSlug: string, ticketUuid: string) {
    try {
      const res = await this._apiRequestService.getRequest('me/fixture/' + fixtureSlug + '/ticket/' + ticketUuid)
      this._store.dispatch(setETicket({ ticket: res.data }))
      return true
    } catch (e: any) {
      return e.error
    }
  }

  async getSeasonPassInformation(seasonPassSlug: string, ticketUuid: string) {
    try {
      const res = await this._apiRequestService.getRequest('me/season-pass/' + seasonPassSlug + '/season-pass-purchase/' + ticketUuid)
      this._store.dispatch(setESeasonPass({ seasonPass: res.data }))
      return true
    } catch (e: any) {
      return e.error
    }
  }

  removeSoldTicketsForFixture() {
    this._store.dispatch(setSoldSeats({ soldSeats: {} }))
  }

  removeSectorDataForFixture() {
    this._store.dispatch(setSectorData({ sectorData: {} as SectorData }))
  }
}
