import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() isProcessing: boolean = false
  @Input() type: string = "submit"
  @Input() disabled: boolean = false
  @Input() style: string = ""
}
