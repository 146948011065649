import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class CashierOrderService {

  constructor(
    private _apiRequestService: ApiRequestService,
  ) { }

  public async getCashierOrders(page: Number | String)
  {
    try {
      const res = await this._apiRequestService.getRequest('cashier-orders?page=' + page)
      return res
    } catch(e: any) {
      return e.error
    }   
  }
}
