import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { ApiRequestService } from 'src/app/services/ApiRequest/api-request.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { NavbarService } from 'src/app/services/Navbar/navbar.service';

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss']
})
export class LoginOrRegisterComponent implements OnInit, OnDestroy {
  public auth: Auth = {
    isUserLoggedIn: false,
  } as Auth

  public common: CommonState = {
    showProfileMenu: false,
  } as CommonState

  private _subscription?: Subscription

  constructor(
    private _store: Store, 
    private _modalService: ModalService,   
    private _authService: AuthService,
    private _navbarService: NavbarService,
  ) {
  }

  ngOnInit(): void {
    this._subscription = this._store.subscribe((state: any) => {
      this.auth = state.auth
      this.common = state.common
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  showLoginModal() {
    this._modalService.showLoginModal()
    this._navbarService.hideMobileMenu()
  }

  openProfileMenu() {
    this._navbarService.toggleProfileMenu()
  }
}
