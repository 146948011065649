<div class="container w-full h-full">
    <div class="rounded-[10px] px-0 max-w-full h-full">
        <div class="bg-white rounded-[10px] h-full flex justify-center py-4">
            <p class="text-center" *ngIf="!pdfUrl">A jegy / bérlet betöltése folyamatban...</p>

            <!--<embed [src]="pdfUrl" class="h-full w-full" type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"/>-->
            <!--<object [data]="pdfUrl" type="application/pdf" width="100%" height="600px">
                <p>Your browser does not support PDFs.
                    <a href="path/to/your/file.pdf">Download the PDF</a>.</p>
            </object>-->
            <iframe id="invoice" *ngIf="pdfUrl" [src]="pdfUrl" class="h-full"></iframe>

        </div>

        <ng-container *ngIf="errorMsg">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="text-center" [innerHTML]="errorMsg"></p>
            </div>
        </ng-container>
    </div>
</div>