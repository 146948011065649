import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonState } from './interfaces/State/Common/common-state';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from './services/Cookie/cookie.service';
import { ConfigService } from './services/Misc/Config/config.service';
import { HelperService } from './services/Misc/Helper/helper.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'csakjegyek.hu';

  public isLandingPage: boolean = false

 constructor(
    private _store: Store,
    private _translateService: TranslateService,
    private _cookieService: CookieService,
    private _configService: ConfigService,
    private _helperService: HelperService,
    private _localizeService: LocalizeRouterService
  ) {
    if (window.location.hostname == 'csakjegyek.hu' || window.location.hostname == 'ticketingexp.com') {
      this.isLandingPage = true
    }

    /* in case of language change */
    _translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const cookie = {
        name: 'language', 
        value: event.lang,
        expireDays: 365 * 10,
      }
      this._cookieService.setCookie(cookie)
    });

    /* fallback language */
    this._translateService.setDefaultLang('en');

    /* current language */
    const currentLanguage = this._helperService.getCurrentLanguage()

    this._configService.isLanguageAvailable(this._helperService.getClient(), this._helperService.getEnvironment(), currentLanguage)
    .then((response) =>{
      this._translateService.use(response ? currentLanguage : 'en');
      this._localizeService.changeLanguage(response ? currentLanguage : 'en')
    })
    .catch((err) => {
      this._translateService.use('en');
      this._localizeService.changeLanguage('en')
    })
  }

  ngOnInit(): void {
    if (!environment.production) {
      this._store.subscribe((state) => {
        console.log(state)
      })
    }
  }
}
