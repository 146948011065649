import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorMsgComponent } from './input-error-msg.component';



@NgModule({
  declarations: [InputErrorMsgComponent],
  imports: [
    CommonModule
  ],
  exports: [
    InputErrorMsgComponent
  ],
})
export class InputErrorMsgModule { }
