import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setStadiumSeats } from 'src/app/actions/stadium.actions';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { setSeatDetails, setSoldSeats } from 'src/app/actions/seats.actions';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';

@Injectable({
  providedIn: 'root'
})
export class StadiumService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async getStadiumByFixtureId(fixtureId: string) {
      const res = await this._apiRequestService.getRequest('fixtures/' + fixtureId)
      this._store.dispatch(setStadiumSeats({stadium: res.data.stadium}))   
  }

  removeStates() {
    this._store.dispatch(setStadiumSeats({stadium: {} as StadiumWithSectorsAndSeats}))
    this._store.dispatch(setSeatDetails({seatDetails: {} as SeatDetails}))
    this._store.dispatch(setSoldSeats({soldSeats: {} as SoldSeats}))
  }

}
