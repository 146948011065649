import { createReducer, on } from "@ngrx/store";
import { setOrderResult } from "../actions/order.action";
import { OrderMain } from "../interfaces/State/Order/OrderMain";
import { OrderResult } from "../interfaces/State/Order/OrderResult";

export const initialState = {
    orderResult: {} as OrderResult,
} as OrderMain

export const OrderReducer = createReducer(
    initialState,
    on(setOrderResult, (state, {orderResult}) => ({...state, orderResult : {...orderResult}}))
);