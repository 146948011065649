import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { Store } from '@ngrx/store';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { Subscription } from 'rxjs';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { SpecialAccessService } from 'src/app/services/SpecialAccess/special-access.service';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { TicketService } from 'src/app/services/Ticket/ticket.service';

@Component({
  selector: 'app-special-access',
  standalone: true,
  imports: [
      CommonModule,  
      XModule,
      FormsModule,
      ReactiveFormsModule,
      InputErrorMsgModule,
      InputModule,
      ButtonModule,
    ],
  templateUrl: './special-access.component.html',
  styleUrls: ['./special-access.component.scss']
})
export class SpecialAccessComponent {
  private _subscription?: Subscription
  public modals: Modals = {} as Modals
  public fixtureSlug?: string 
  public error: string = ""
  public success: boolean = false
  public isRequesting: boolean = false
  private formSubmitAttempt: boolean = false;
  public refreshing: boolean = false
  public specialAccessForm = this._formBuilder.group({
    code: ['', [Validators.required]],
  });


  constructor(    
    private _modalService: ModalService,  
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _specialAccessService: SpecialAccessService,
    private _cookieService: CookieService,
    private _ticketService: TicketService,
  ){

  }

  ngOnInit(): void {
    this._subscription = this._store.subscribe((state: any) => {
      this.modals = state.modals
      this.fixtureSlug = this.modals.modal.data['fixtureSlug']
    })
  }


  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  hideModal() {
    this._modalService.hideModal()
  }

  setValue(property: string, value: any) {
    this.specialAccessForm.get(property)?.setValue(value)
  }


  async onSubmit() {
    if(this.isRequesting) return ;
    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""
    if(!this.specialAccessForm.valid) {
      this.isRequesting = false
      return ;
    }
  
    const res = await this._specialAccessService.validateCode(this.code!.value!, this.fixtureSlug!)

    this.isRequesting = false

    if(res === true) {
      this.success = true
      this.refreshing = true
      this._cookieService.setCookie({name: 'special-access-code', value: this.code!.value!})
      this.reset()
      await this._ticketService.getSectorDataForFixture(this.fixtureSlug!)
      this.hideModal()
      return ;
    }
    
    this.success = false
    this.error = res
  }

  reset() {
    this.specialAccessForm.reset()
    this.formSubmitAttempt = false
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.specialAccessForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.specialAccessForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.specialAccessForm.get(field)!.valid && this.formSubmitAttempt
  }

  get code() { return this.specialAccessForm.get('code') }

}
