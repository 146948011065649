import { createReducer, on } from "@ngrx/store";
import { setSoldSeats, setSpecificSoldSeatToTrue, seatDeleting, seatDeleted, removeSpecificSoldSeat, setSeatDetails } from "../actions/seats.actions";
import { SoldSeats } from "../interfaces/State/Seat/sold-seats";
import { DeletingSeat } from "../interfaces/State/Seat/deleting-seat";
import { SeatDetails } from "../interfaces/State/Seat/seat-details";

export const initialState = {
    soldSeats: {} as SoldSeats,
    deletingSeats: {} as DeletingSeat,
    seatDetails : {} as SeatDetails,
}

export const SeatsReducer = createReducer(
    initialState,
    on(setSoldSeats, (state, {soldSeats}) => ({...state, soldSeats: soldSeats})),
    on(setSeatDetails, (state, {seatDetails}) => ({...state, seatDetails: seatDetails})),
    on(seatDeleting, (state, {seatId}) => ({...state, deletingSeats: {...state.deletingSeats, [seatId]:true}})),
    on(seatDeleted, (state, {seatId}) => ({...state, deletingSeats: {...state.deletingSeats, [seatId]:false}})),
    on(setSpecificSoldSeatToTrue, (state, {seatId}) => ({...state, soldSeats : {...state.soldSeats, [seatId] : 'n'}})),
    on(removeSpecificSoldSeat, (state, { seatId }) => { const { [seatId]: removed, ...newSoldSeats } = state.soldSeats; return { ...state, soldSeats: newSoldSeats }; })
);