import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule,  FormsModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() placeholder: string = ""
  @Input() value: string = ""
  @Input() type: string = "text"
  @Input() customCssClasses: string = ""
  @Input() required: boolean = false
  @Input() options?: Array<String>
  
  @Output() inputValueChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  
  public inputValueChangedFn(event: any)
  {
    this.value = event
    this.inputValueChangedEvent.emit(event);
  }
}
