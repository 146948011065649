import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XComponent } from './x.component';



@NgModule({
  declarations: [XComponent],
  imports: [
    CommonModule
  ],
  exports : [
    XComponent
  ]
})
export class XModule { }
