import { createReducer, on } from "@ngrx/store";
import { setSoldSeats, setSpecificSoldSeatToTrue } from "../actions/seats.actions";
import { SectorData } from "../interfaces/State/Sector/sector-data";
import { setSectorData } from "../actions/sector.actions";

export const initialState = {
    sectorData: {} as SectorData
}

export const SectorReducer = createReducer(
    initialState,
    on(setSectorData, (state, {sectorData}) => ({...state, sectorData: sectorData})),
);