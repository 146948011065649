import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { Store } from '@ngrx/store';
import { setOpenedSeasonPass, setSeasonPasses } from 'src/app/actions/season-pass.actions';
import { setStadiumSeats } from 'src/app/actions/stadium.actions';
import { Index } from "src/app/interfaces/State/SeasonPass";
import { setSeatDetails, setSoldSeats } from 'src/app/actions/seats.actions';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';
@Injectable({
  providedIn: 'root'
})
export class SeasonPassService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async getSeasonPasses() {
    const res = await this._apiRequestService.getRequest('season-passes')
    this._store.dispatch(setSeasonPasses({ seasonPasses: res.data }))
  }

  async getSeasonPass(seasonPassSlug: string) {
    try {
      let res = await this._apiRequestService.getRequest('season-passes/' + seasonPassSlug + '?seats=false')

      this._store.dispatch(setStadiumSeats({ stadium: res.data.stadium }))
      this._store.dispatch(setOpenedSeasonPass({ seasonPass: res.data.season_pass }))

      if (res.data.season_pass.show_seat_map) {
        this.getSeasonPassWithSeats(seasonPassSlug)
      }

    } catch (e: any) {
      throw e
    }
  }

  async getSeasonPassWithSeats(seasonPassSlug: string) {
    try {
      let res = await this._apiRequestService.getRequest('season-passes/' + seasonPassSlug)

      this._store.dispatch(setStadiumSeats({ stadium: res.data.stadium }))
      this._store.dispatch(setSeatDetails({ seatDetails: res.data.seat_details }));

    } catch (e: any) {
      throw e
    }
  }

  removeStates() {
    this._store.dispatch(setStadiumSeats({ stadium: { name: '', sectors: [], id: 0, viewBox: '' } }))
    this._store.dispatch(setOpenedSeasonPass({ seasonPass: {} as Index }))
    this._store.dispatch(setSeatDetails({ seatDetails: {} as SeatDetails }))
    this._store.dispatch(setSoldSeats({ soldSeats: {} as SoldSeats }))
  }
}
