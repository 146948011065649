export enum CartError {

    /* CART ERROR CODES */
    CART_NOT_FOUND = 900,
    CART_BELONGS_TO_ANOTHER_USER = 901,
    CART_INVOICE_DETAILS_EMPTY = 902,
    CART_NOT_MY_CART = 903,

    /* AUTH ERROR CODES */
    NOT_AUTHENTICATED = 499,
    NOT_AUTHENTICATED_AND_LOGOUT = 498,
}