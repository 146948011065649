<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 10</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="cart" transform="translate(-40.000000, -87.000000)">
            <g id="Group-10" transform="translate(40.000000, 87.000000)">
                <circle id="Oval" fill="#D7003F" cx="13" cy="13" r="13"></circle>
                <polyline id="Path-3" stroke="#FFFFFF" transform="translate(12.250000, 12.805556) rotate(90.000000) translate(-12.250000, -12.805556) " points="7.25 10.5833333 12.25 15.0277778 17.25 10.5833333"></polyline>
            </g>
        </g>
    </g>
</svg>