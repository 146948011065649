import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideMobileMenu, hideProfileMenu, showMobileMenu, showProfileMenu, toggleProfileMenu } from 'src/app/actions/common.actions';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public common: CommonState = {} as CommonState

  constructor( private _store: Store,) { }

  hideMobileMenu() {
    this._store.dispatch(hideMobileMenu())
  }

  showMobileMenu() {
    this._store.dispatch(showMobileMenu())
  }

  showProfileMenu() {
    this._store.dispatch(showProfileMenu())
  }

  hideProfileMenu() {
    this._store.dispatch(hideProfileMenu())
  }

  toggleProfileMenu() {
    this._store.dispatch(toggleProfileMenu())
  }

}
