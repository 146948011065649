import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  public mapOnly: boolean = false

  constructor(
    private _route: ActivatedRoute,
  ) {
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }
}
