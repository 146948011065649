import { createReducer, on } from "@ngrx/store";
import { clearCurrentMySeasonPasses, setCurrentMySeasonPasses, setExpiredMySeasonPasses, updateCurrentMySeasonPass } from "../actions/myseasonpasses.actions";
import { State } from "../interfaces/State/MySeasonPasses/state";
import { clearESeasonPass, setESeasonPass } from "../actions/mytickets.actions";
import { SingleSeasonPass } from "../interfaces/State/MySeasonPasses/singleSeasonPass";
import { MySeasonPassIndex } from "../interfaces/State/MySeasonPasses";

export const initialState = {
    current: undefined,
    expired: undefined,
    eSeasonPass: {} as SingleSeasonPass,
} as State

export const MySeasonPassReducer = createReducer(
    initialState,
    on(setCurrentMySeasonPasses, (state, {seasonPasses}) => ({...state, current : seasonPasses})),
    on(clearCurrentMySeasonPasses, (state) => ({...state, current : undefined})),
    on(setExpiredMySeasonPasses, (state, {seasonPasses}) => ({...state, expired : seasonPasses})),
    on(setESeasonPass, (state, {seasonPass}) => ({...state, eSeasonPass : seasonPass})),
    on(clearESeasonPass, (state,) => ({...state, eSeasonPass : {} as SingleSeasonPass })),
    on(updateCurrentMySeasonPass, (state, {seasonPassPurchase, seasonPassSlug }) => {
        let updatedCurrent: {[key:string] : MySeasonPassIndex} = {}

        if(state.current !== undefined) {
          Object.keys(state.current)?.map(slug => {
            let seasonPass = state.current?.[slug]

            if (seasonPass?.details.slug === seasonPassSlug) {
              
              const updatedSeasonPasses = seasonPass.season_passes?.map(t => {
      
                if (t.uuid === seasonPassPurchase.uuid) {
                  return { ...t, ...seasonPassPurchase };
                }
      
                return t;
              }) ?? [];

              updatedCurrent[slug] = {
                  details : seasonPass.details,
                  season_passes: updatedSeasonPasses,
              }
            } else {
              updatedCurrent[slug] = seasonPass!
            }
      
          });
        }
        
        return {
          ...state,
          current: updatedCurrent,
        };
    }),
);