import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setUpcomingFixtures } from 'src/app/actions/fixture.actions';
import { State } from 'src/app/interfaces/State/Fixture/state';
import { FixtureService } from 'src/app/services/Fixture/fixture.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy{
  public fixtures: State = {
    upcomingFixtures : undefined
  }

  private _subscription?: Subscription

  constructor(
    private _fixtureService: FixtureService,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._fixtureService.getFixtures()

    this._subscription = this._store.subscribe((state: any) => {
      this.fixtures = state.fixtures
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }
}
