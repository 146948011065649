import { createReducer, on } from "@ngrx/store";
import { setHomepage } from "../actions/homepage.actions";
import { Homepage } from "../interfaces/State/Homepage/homepage";

export const initialState = {
} as Homepage

export const HomepageReducer = createReducer(
    initialState,
    on(setHomepage, (state, {homepage}) => ({...state, ...homepage})),
);