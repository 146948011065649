import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Index } from 'src/app/interfaces/State/SeasonPass';

@Component({
  selector: 'app-season-pass-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input() seasonPasses?: any
  @Input() needEmptyText: Boolean = true
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void
   {
   }

  toggle() {
   
  }


}
