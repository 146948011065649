import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRegisterModalComponent } from './login-register-modal.component';
import { LoginRegisterContentModule } from './_partials/login-register-content/login-register-content.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';

@NgModule({
  declarations: [LoginRegisterModalComponent],
  imports: [
    CommonModule,
    LoginRegisterContentModule,
    XModule,
  ],
  exports: [
    LoginRegisterModalComponent
  ],
})
export class LoginRegisterModalModule { }
