import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ModalType } from 'src/app/enums/modals/modalType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-modals',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy{
  public modals: Modals = {} as Modals
  public auth: Auth = {} as Auth

  private _subscription?: Subscription 

  constructor(
    private _store: Store,
    private _modalService: ModalService,   

  ) {
  }

  hideModal() {
    return ;
    if(window.outerWidth <= 600) return ;
    
    this._modalService.hideModal()
  }

  ngOnInit(): void {
    this._subscription = this._store.subscribe((state: any) => {
      this.modals = state.modals
      this.auth = state.auth
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  get modalType() {
    return this.modals.modal.modalType!
  }

  public get modalTypes(): typeof ModalType {
    return ModalType; 
  }

}
