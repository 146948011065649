<div class="container ">
    <div class="rounded-[10px] my-10 px-0 sm:max-w-full max-w-[80%] sm:my-4">
        <ng-container *ngIf="orderResult && orderResult.id && orderResult.success">
            <!-- SUCCESS -->
            <h1 class="text-white font-bold text-lg">{{ 'order-result.successful-order' | translate }}</h1>
            <p class="text-white" *ngIf="isCustomer()">{{ 'order-result.list-1' | translate }}
                <ng-container *ngIf="!mapOnly; else spanElement">
                    <a [routerLink]="['/my-tickets'] | localize"   class="text-[var(--red)]">{{ 'order-result.list-2' | translate }}</a>
                </ng-container>
                <ng-template #spanElement>
                    <span class="text-[var(--red)]">{{ 'order-result.list-2' | translate }}</span>
                </ng-template>
                
                {{ 'order-result.list-3' | translate}}</p>

            <!-- MERGED TICKETS -->
             <div class="flex justify-between mt-8" *ngIf="isCashier()">
                <div *ngIf="isCashier()">
                    <app-button [style]="' h-full '" 
                        class="font-bold text-[13px] text-[#979797] rounded-[10px] border-[#979797] flex gap-[5px] justify-between items-center" 
                        (click)="printAllTickets()">
                        {{ isPrinting() ? ('order-result.printing-all-tickets' | translate) : ('order-result.print-all-tickets' | translate) }}
                    </app-button>
                </div>

                <div class="inline-block max-w-full" *ngIf="orderResult.invoice_email">
                    <app-button
                    *ngIf="orderResult.has_merged_document"
                    [style]="' h-full '"
                    [isProcessing]="isSendingSuccessfulEmail" 
                    (click)="sendSuccessfulEmail()">
                    {{ 'order-result.send-tickets' | translate }}: {{ orderResult.invoice_email }}</app-button> 
                </div>
            </div>
            <!-- MERGED TICKETS -->

            <!-- FIXTURE -->
            <div class="inline-block max-w-full w-[340px] mt-4" *ngIf="isCashier()">
                <app-button
                    *ngIf="orderResult.has_merged_document"
                    [style]="' h-full '"
                    [isProcessing]="isDownloadingMergedTickets" 
                    (click)="downloadMergedTickets()">
                    {{ 'order-result.open-all-tickets-and-season-passes' | translate }}</app-button> 
            
                <div *ngIf="!orderResult.has_merged_document" class="mt-4">
                    <p class="font-black text-white">{{ orderResult.items.document_generation_finished }}/{{ orderResult.items.count }} {{ 'order-result.tickets-are-generated' | translate }}</p>
                    <div class="w-[300px] bg-white h-[10px] rounded-[10px] mt-[10px] relative">
                        <div 
                            class="absolute transition-all left-0 top-0 h-[10px] rounded-[8px] bg-[var(--red)] "
                            [style]="'width:' + (orderResult.items.document_generation_finished / orderResult.items.count * 100 ) + '%'"></div>
                    </div>
                </div>
                
            </div>

            <div class="mt-6 flex flex-col gap-8" *ngIf="orderResult.tickets.length">
                <ng-container *ngFor="let fixture of orderResult.tickets">
                   <app-my-ticket 
                        [auth]="auth" 
                        [printTrigger]="remainingTicketCountForPrint$" 
                        [fixtureWrapperObject]="fixture"
                        [isInvoiceIssued]="orderResult?.is_invoice_issued"
                        >
                </app-my-ticket>
               </ng-container>
            </div>

            <!-- SEASON PASS -->
            <div class="mt-6 flex flex-col gap-8" *ngIf="orderResult.season_passes">
                <ng-container *ngFor="let object of orderResult.season_passes| keyvalue">
                   <app-my-season-pass 
                        [auth]="auth" 
                        [seasonPassWrapperObject]="object.value"
                        [isInvoiceIssued]="orderResult?.is_invoice_issued"    
                    >
                    </app-my-season-pass>
                </ng-container>
            </div>
        </ng-container>
        <!-- FAILED PAYMENT -->
        <ng-container *ngIf="orderResult && orderResult.id && !orderResult.success && !orderResult.is_processing">
            <div class="flex justify-between items-center">
                <h1 class="text-white font-bold text-lg">{{ 'order-result.unsuccesful-order' | translate }}</h1>
                <app-button
                    *ngIf="(orderResult?.can_be_retried && this.remainingSeconds != -1)"
                    [style]="' h-full '"
                    (click)="retry()"
                    [isProcessing]="isRetrying" >{{ actionButtonText }}</app-button>
            </div>
        </ng-container>
        <!-- PENDING PAYMENT -->
        <ng-container *ngIf="orderResult && orderResult.id && orderResult.is_processing">
            <div class="flex items-center gap-3">   
                <div class="loader !w-[30px]"></div>         
                <h1 class="text-white font-bold text-lg">A fizetés feldolgozása folyamatban az OTP SimplePay által</h1>
            </div>
        </ng-container>

        <!-- ORDER DETAILS -->
        <ng-container *ngIf="orderResult && orderResult.id">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <h1 class="mb-4">{{ 'order-result.order-details' | translate }}:</h1>
                <p><strong>{{ 'order-result.amount' | translate }}</strong>: {{ orderResult.total_price }}</p>
                <p><strong>{{ 'order-result.selected-payment-method' | translate }}</strong>: {{ orderResult.payment_provider }}</p>  
                <p><strong>{{ 'order-result.status' | translate }}</strong>: {{ orderResult.status }}</p>
                <p><strong>{{ 'order-result.identifier' | translate }}</strong>: {{ orderResult.id }}</p>   
                <div *ngIf="orderResult.text?.length" class="mt-4">
                    <p *ngFor="let text of orderResult.text" [innerHtml] = "text" class="min-h-[24px]"></p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingInitially">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="">{{ 'order-result.loading' | translate }}</p>
            </div>
        </ng-container>
        <ng-container *ngIf="errorMsg">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="text-center" [innerHTML]="errorMsg"></p>
            </div>
        </ng-container>
    </div>
</div>