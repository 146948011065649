import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setOpenedFixture, setUpcomingFixtures } from 'src/app/actions/fixture.actions';
import { setStadiumSeats } from 'src/app/actions/stadium.actions';
import { Index } from 'src/app/interfaces/State/Fixture';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { setSeatDetails, setSoldSeats } from 'src/app/actions/seats.actions';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
@Injectable({
  providedIn: 'root'
})
export class FixtureService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async getFixtures() {
    const res = await this._apiRequestService.getRequest('fixtures')
    this._store.dispatch(setUpcomingFixtures({fixtures: res.data.fixtures}))   
  }

  async getFixture(fixtureId: string) {
    let res = await this.getFixtureWithoutSeats(fixtureId)
    
    if(res.data.fixture.show_seat_map) {
      this.getFixtureWithSeats(fixtureId)
    }
  }

  async getFixtureWithoutSeats(fixtureId: string) {
    let res = await this._apiRequestService.getRequest('fixtures/' + fixtureId + '?seats=false' )
    this._store.dispatch(setStadiumSeats({stadium: res.data.stadium}))   
    this._store.dispatch(setOpenedFixture({fixture: res.data.fixture}))
    return res
  }

  async getFixtureWithSeats(fixtureId: string) {
    let res = await this._apiRequestService.getRequest('fixtures/' + fixtureId)
    this._store.dispatch(setStadiumSeats({stadium: res.data.stadium})) 
    this._store.dispatch(setSeatDetails({seatDetails: res.data.seat_details}));
  }

  removeStates() {
    this._store.dispatch(setStadiumSeats({stadium: { name: '', sectors: [] , id : 0, viewBox: ''}}))   
    this._store.dispatch(setOpenedFixture({fixture: {} as Index}))
    this._store.dispatch(setSeatDetails({seatDetails: {} as SeatDetails}));
    this._store.dispatch(setSoldSeats({soldSeats: {} as SoldSeats}));
  }
}
