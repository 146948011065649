import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StadiumComponent } from './stadium.component';
import { SeatModule } from './_partials/seat/seat.module';
import { SpinnerComponent } from 'src/app/components/assets/icons/spinner/spinner.component';

@NgModule({
  declarations: [
    StadiumComponent,
  ],
  imports: [
    CommonModule,
    SpinnerComponent,
  ],
  exports : [
    StadiumComponent
  ],
})
export class StadiumModule { }
