import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { MySeasonPassTypes } from 'src/app/enums/seasonPasses/mySeasonPass/mySeasonPassTypes';
import { setCurrentMySeasonPasses, setExpiredMySeasonPasses } from 'src/app/actions/myseasonpasses.actions';

@Injectable({
  providedIn: 'root'
})
export class MySeasonPassService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  public async downloadSeasonPass(seasonPassSlug: string, seatId: number) {
    return await this._apiRequestService.getRequest('season-pass-purchases/download/' + seasonPassSlug + '/' + seatId)
  }

  public getAppleWalletPass(seasonPassSlug: string, seatId: number) {
    this._apiRequestService.downloadFileRequest('season-pass-purchases/apple-wallet-pass/' + seasonPassSlug + '/'  + seatId, [], 'application/vnd.apple.pkpass', { filename: 'pass', extension: 'pkpass' })
  }

  public async getCurrentSeasonPasses() {
    try {
      const res = await this._apiRequestService.getRequest('season-pass-purchases/' + MySeasonPassTypes.CURRENT)
      this._store.dispatch(setCurrentMySeasonPasses({ seasonPasses: res.data }))  
    } catch(e: any) {
      ;
    }
  }

  public async getExpiredSeasonPasses() {
    const res = await this._apiRequestService.getRequest('season-pass-purchases/' + MySeasonPassTypes.EXPIRED)
    this._store.dispatch(setExpiredMySeasonPasses({ seasonPasses: res.data }))
  }
}
