import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-error-msg',
  templateUrl: './input-error-msg.component.html',
  styleUrls: ['./input-error-msg.component.scss']
})
export class InputErrorMsgComponent {
  @Input() show: boolean = false
  @Input() errorMsg: string = ""
  @Input() customCssClasses: string = ""
}
