import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { State as MyTickestState } from 'src/app/interfaces/State/MyTickets/state';
import { State as MySeasonPassesState } from 'src/app/interfaces/State/MySeasonPasses/state';

import { MySeasonPassService } from 'src/app/services/MySeasonPass/my-season-pass.service';
import { MyTicketService } from 'src/app/services/MyTicket/my-ticket.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit, OnDestroy{
  public fixtures?: MyTickestState

  public seasonPasses?: MySeasonPassesState 

  public auth: Auth = {} as Auth

  private _subscription?: Subscription

  public mapOnly: boolean = false

  public waitingOver: boolean = false

  constructor(
    private _myTicketService: MyTicketService,
    private _mySeasonPassService: MySeasonPassService,
    private _store: Store,
    private _route: ActivatedRoute,
  ) {
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnInit(): void {
    this._myTicketService.getUpcomingTickets()

    this._mySeasonPassService.getCurrentSeasonPasses()

    this._subscription = this._store.subscribe((state: any) => {
      this.fixtures = state.myTickets
      this.seasonPasses = state.mySeasonPasses
      this.auth = state.auth
    })

    setTimeout(() => {
      this.waitingOver = true
    }, 30000)
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }


  public hasCurrentSeasonPasses() {
    return this.seasonPasses?.current ? Object.keys(this.seasonPasses.current).length : false
  }

  public hasExpiredSeasonPasses() {
    return this.seasonPasses?.expired ? Object.keys(this.seasonPasses.expired).length : false
  }

  public hasCurrentTickets() {
    return this.fixtures?.upcoming ? Object.keys(this.fixtures.upcoming).length : false
  }

  public hasExpiredTickets() {
    return this.fixtures?.past ? Object.keys(this.fixtures.past).length : false
  }

  public isLoading() {
    if(
     this.fixtures?.upcoming == undefined || 
     this.seasonPasses?.current == undefined
    ) return true

    return false
  }
}
