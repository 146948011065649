import { Component, Input } from '@angular/core';
import { Index } from 'src/app/interfaces/State/SeasonPass';

@Component({
  selector: 'app-single-season-ticket',
  templateUrl: './single-season-ticket.component.html',
  styleUrls: ['./single-season-ticket.component.scss']
})
export class SingleSeasonTicketComponent {
  @Input() seasonPass: Index = {} as Index
  public open: boolean = false

  public toggle() {
    this.open = !this.open
  }
}
