import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { ModalsModule } from '../_partials/modals/main/main.module';
import { NavbarModule } from '../_partials/navbar/navbar.module';
import { LoginOrRegisterModule } from '../_partials/login-or-register/login-or-register.module';
import { FooterModule } from '../_partials/footer/footer.module';
import { CookiebarComponent } from '../_partials/cookiebar/cookiebar.component';

@NgModule({
  declarations: [DefaultComponent],
  imports: [
    CommonModule,
    RouterModule,
    ModalsModule,
    LoginOrRegisterModule,
    NavbarModule,
    FooterModule,
    CookiebarComponent,
  ],
  exports : [
    DefaultComponent
  ]
})
export class DefaultModule { }
