import { createReducer, on } from "@ngrx/store";
import { setOpenedSeasonPass, setSeasonPasses } from "../actions/season-pass.actions";
import { State } from "../interfaces/State/SeasonPass/state";

export const initialState = {
    seasonPasses: undefined,
} as State

export const SeasonPassReducer = createReducer(
    initialState,
    on(setSeasonPasses, (state, {seasonPasses}) => ({...state, seasonPasses : seasonPasses})),
    on(setOpenedSeasonPass, (state, {seasonPass}) => ({...state, openedSeasonPass : seasonPass})),
);