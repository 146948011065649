import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomerComponent } from './zoomer.component';



@NgModule({
  declarations: [ZoomerComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ZoomerComponent
  ]
})
export class ZoomerModule { }
