import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';


@NgModule({
  declarations: [InputComponent],
  providers: [
    provideNgxMask(),
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskDirective,
  ], 
  exports : [
    InputComponent
  ],
})
export class InputModule { }
