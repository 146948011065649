<router-outlet *ngIf="!isLandingPage"></router-outlet>

<div *ngIf="isLandingPage">
    <div class="fixed top-0 left-0 flex justify-center items-center z-[1] w-screen h-screen bg-[rgba(0,0,0,.6)]">
        <a href="https://szpari.csakjegyek.hu" class="bg-[var(--red)] block font-bold hover:bg-[var(--red-hover)] px-[27px] py-[12px] rounded-[10px] sm:text-center text-[13px] text-white transition-all uppercase w-max">Tovább a Szpari felületére</a>
    </div>
    <video preload="auto" autoplay loop [muted]="true" playsinline class="absolute z-0 w-full h-full object-cover">
        <source  type="video/mp4" src="https://csakjegyek-production-public.s3.eu-central-1.amazonaws.com/clients/szpari/settings/assets/video.mp4">
        Your browser does not support the video tag.
    </video>
</div>