import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setHomepage } from 'src/app/actions/homepage.actions';
import { ApiRequestService } from '../ApiRequest/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  public async getHomepage() {
      const res = await this._apiRequestService.getRequest('homepage')
      this._store.dispatch(setHomepage({homepage: res.data}))   
  }
}
