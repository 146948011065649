import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, from, switchMap, throwError } from 'rxjs';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { AuthService } from 'src/app/services/Auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _cookieService: CookieService,
    private _authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    const bearerToken = this._cookieService.getCookie('access_token')

    if(bearerToken) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + bearerToken,
        }
      })
    }

    const expiresAt = this._cookieService.getCookie('expires_at')
    const refreshToken = this._cookieService.getCookie('refresh_token')

    /* refresh token */
    if(!this.isRefreshingTokenRequest(request.url) && refreshToken && (this.tokenIsAboutToExpire(parseInt(expiresAt)) || !expiresAt) ) {
      
      return from(this._authService.refreshToken(refreshToken)).pipe(
        switchMap(() => {
          // Retry the original request with the new token
          const clonedRequest = this.retryRequest(request)

          return next.handle(clonedRequest);
        }),
        catchError((refreshError: any) => {
          // Handle token refresh error, redirect to login page, or notify user
          //return throwError(refreshError);
          return next.handle(request)
        })
      );
    }
   
    return next.handle(request);
  }

  private isRefreshingTokenRequest(url: string): Boolean {
    return url.indexOf('/me/refresh') !== -1
  }

  /* decides whether an access token is about to expire */
  private tokenIsAboutToExpire(expiresAt: number): Boolean {
    const expirationMinutes = 10
    return expiresAt - (expirationMinutes * 60) < Math.floor(Date.now() / 1000) ? true : false
  }

  /* clone request and append access token */
  private retryRequest(req: HttpRequest<any>): HttpRequest<any> {
    const token = this._cookieService.getCookie('access_token')

    if (!token) {
      return req
    }

    return req.clone({
      setHeaders: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }
}
