<div
    class="p-10 drop-shadow-[0_0_25px_rgba(0,0,0,1)] bg-white relative mt-[10vh] rounded-[10px] w-[600px] max-w-[90vw] sm:!mt-[60px] max-h-[84vh] overflow-y-scroll">
    <button (click)="hideModal()" class="absolute top-[30px] right-[30px]" type="button" id="closeButton" role="button">
        <app-x></app-x>
    </button>
    <h1 class="text-[15px] font-bold" >A szektor megnyitásához hozzáférési kód szükséges!</h1>
    <p *ngIf="error" class="text-[var(--red)] text-center mb-6 break-words mt-6" [innerHTML]="error"></p>

    <div class="h-[calc(100%-93px)] overflow-scroll mt-5" >
        <form [formGroup]="specialAccessForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
            <ng-container *ngIf="!success">
                <div class="mb-5">
                    <app-input 
                        [customCssClasses]="isFieldInvalidAtAll('code') ? 'border border-[var(--red)]' : ''" 
                        placeholder="Hozzáférési kód" 
                        (inputValueChangedEvent)="setValue('code', $event)"></app-input>
                    <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('code', 'required')" errorMsg="A hozzáférési kód megadása kötelező!"></app-input-error-msg>
                </div>
                <div >
                    <app-button [isProcessing] = "isRequesting">Kód aktiválása</app-button>
                </div>
            </ng-container>
            <p *ngIf="success" class="text-center font-bold text-[var(--green)]">Aktiválás sikeres!</p> 
            <p *ngIf="refreshing" class="mt-5 text-center">Az adatok frissítése folyamatban...</p>  

        </form> 
    </div>
</div>