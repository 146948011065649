import { createReducer, on } from "@ngrx/store";
import { hideMobileMenu, hideProfileMenu, setClient, showMobileMenu, showProfileMenu, toggleProfileMenu } from "../actions/common.actions";
import { CommonState } from "../interfaces/State/Common/common-state";

export const initialState = {
    client: '',
    showMobileMenu: false,
    showProfileMenu: false,
} as CommonState

export const CommonReducer = createReducer(
    initialState,
    on(setClient, (state, {client}) => ({...state, client : client})),
    on(showMobileMenu, (state) => ({...state, showMobileMenu : true})),
    on(hideMobileMenu, (state) => ({...state, showMobileMenu : false})),
    on(showProfileMenu, (state) => ({...state, showProfileMenu : true})),
    on(hideProfileMenu, (state) => ({...state, showProfileMenu : false})),
    on(toggleProfileMenu, (state) => ({...state, showProfileMenu : !state.showProfileMenu})),
);