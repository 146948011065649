import { Component, Input } from '@angular/core';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-login-register-modal',
  templateUrl: './login-register-modal.component.html',
  styleUrls: ['./login-register-modal.component.scss']
})
export class LoginRegisterModalComponent{
  type: string = 'login'
  
  @Input() auth!: Auth

  constructor(    
    private _modalService: ModalService,   
  ){

  }

  hideModal() {
    this._modalService.hideModal()
  }
}
