import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { SpinnerComponent } from '../../assets/icons/spinner/spinner.component';



@NgModule({
  declarations: [ButtonComponent],
  imports: [
    CommonModule,
    SpinnerComponent,
  ],
  exports: [
    ButtonComponent,
  ],
})
export class ButtonModule { }
